/* --- Web loader css start --- */
html[data-theme=light-theme] .web-preloader {
    background-color: var(--ghost-white);
}
/* --- Web loader css end --- */



/* --- Header css start --- */
html[data-theme=light-theme] .custom-header .ch-inner{
    border-color: var(--black-10);
    background-color: var(--white);
}

@media screen and (max-width:768px) {
    html[data-theme=light-theme] .custom-header {
        background-color: var(--white);
        border-bottom-color: var(--black-10);
    }

    html[data-theme=light-theme] .custom-header .ch-inner{
        padding: 0;
        border: none;
        background-color: transparent;
    }
}

/* --- Header (theme mode btn start) --- */
html[data-theme=light-theme] .cr-theme-btn .cr-tb-lable{
    color: var(--oxford-blue);
    font-weight: 500;
}
/* --- Header (theme mode btn end) --- */


/* --- Header (cr icons bx start) --- */
html[data-theme=light-theme] .cr-icons-link{
    color: var(--oxford-blue);
    background-color: var(--ghost-white);
    border: 1px solid var(--black-10);
}

html[data-theme=light-theme] .cr-icons-link .cr-num{
    border: 2px solid var(--ghost-white);
}

html[data-theme=light-theme] .cr-drop-item:hover > .cr-icons-link,
html[data-theme=light-theme] .cr-icons-link:hover{
    color: var(--adiant-orchid);
    background-color: var(--adiant-orchid-09);
    border-color: var(--adiant-orchid-15);
}
/* --- Header (Cr icons bx end) --- */


/* ---  Header css (Aside Menu Icon Start) --- */
html[data-theme=light-theme] .menu-icon-bx {
    background-color: var(--ghost-white);
    border: 1px solid var(--black-10);
}

html[data-theme=light-theme] .menu-icon-bx span {
    opacity: 1;
    background-color: var(--slate-grey);
}

html[data-theme=light-theme] .menu-icon-bx:hover span,
html[data-theme=light-theme] .menu-icon-bx.active span{
    opacity: 1;
    background-color: var(--oxford-blue);
}
/* ---  Header css (Aside Menu Icon end) --- */


/* ---  Header css (smb min btn start) --- */
html[data-theme=light-theme] .smb-min-btn{
    color: var(--oxford-blue);
    background-color: var(--ghost-white);
    border: 1px solid var(--black-10);
}

html[data-theme=light-theme] .smb-min-btn:hover{
    color: var(--adiant-orchid);
    background-color: var(--adiant-orchid-09);
    border-color: var(--adiant-orchid-15);
}
/* ---  Header css (smb min btn end) --- */

/* --- Header css end --- */



/* --- Sidemenu css start --- */
html[data-theme=light-theme] .side-menu-bx{
    background-color: var(--white);
    border-color: var(--black-10);
}

/* --- */
html[data-theme=light-theme] .side-menu-bx .smb-header{
    background-color: var(--white);
}
/* --- */

/* --- */
html[data-theme=light-theme] .side-menu-bx .smb-link:not(.highlight-link){
    color: var(--oxford-blue);
    font-weight: 500;
}

html[data-theme=light-theme] .side-menu-bx .smb-link:not(.highlight-link):hover{
    background-color: var(--black-10);
}

html[data-theme=light-theme] .side-menu-bx .smb-link:not(.highlight-link).active{
    color: var(--oxford-blue);
    background: var(--ghost-white);
}

html[data-theme=light-theme] .side-menu-bx .smb-link.highlight-link{
    font-weight: 500;
}
/* --- */

/* --- Sidemenu css end --- */



/* --- Flash message start --- */
html[data-theme=light-theme] .flash-message{
    border: none;
    background-color: var(--white);
    border-color: var(--black-10);
}

html[data-theme=light-theme] .flash-message .flash-text {
    color: var(--oxford-blue);
}
/* --- Flash message end --- */



/* --- Style css start ---- */
html[data-theme=light-theme] a, html[data-theme=light-theme] a:hover {
    color: var(--oxford-blue);
}


/* --- Body tag start --- */
html[data-theme=light-theme] body {
    color: var(--oxford-blue);
    background-color: var(--ghost-white);
}
/* --- Body tag end --- */


/* --- Skeletant Css Start --- */
html[data-theme=light-theme] .skeletant-design {
    background-color: var(--ghost-white);
    background-image: linear-gradient(45deg, #fbfbfb 30%, #f3f4f6 50%, #fbfbfb 70%);
}


html[data-theme=light-theme] .table-skeletant-bx .skeletant-design{
    border-color: var(--black-10);
}
/* --- Skeletant Css End --- */


/* --- User info bx start --- */
html[data-theme=light-theme] .user-info-bx{
    box-shadow: 0 0 0 1px var(--black-10);
    background: var(--ghost-white);
}

html[data-theme=light-theme] .user-info-bx:hover{
    box-shadow: 0 0 0 1px var(--adiant-orchid-15);
    background-color: var(--adiant-orchid-09);
}

html[data-theme=light-theme] .user-info-bx .uib-img{
    box-shadow: 0 0 0 1px var(--black-10);
    background-color: var(--white);
}

html[data-theme=light-theme] .user-info-bx .uib-data .uib-name{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .user-info-bx .uib-data .uib-other{
    color: var(--slate-grey);
}
/* --- User info bx end --- */



/* --- Common color status bx start --- */
html[data-theme=light-theme] .status-white {
    color: var(--oxford-blue) !important;
}
/* --- Common color status bx end --- */



/* --- Screen loader start --- */
html[data-theme=light-theme] .screen-loader-bx {
    background-color: var(--ghost-white);
}
/* --- Screen loader end --- */



/* --- Alert Message start --- */
html[data-theme=light-theme] .alert-text-bx{
    background-color: var(--white);
}

html[data-theme=light-theme] .alert-text-bx .atb-heading{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .alert-text-bx .atb-text{
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .alert-text-bx .atb-btn{
    color: var(--oxford-blue);
    border:1px solid var(--oxford-blue);
}

html[data-theme=light-theme] .alert-text-bx .atb-btn:hover{
    color: var(--white);
    background-color: var(--oxford-blue);
    border-color: var(--oxford-blue);
}

html[data-theme=light-theme] .alert-text-bx .atb-btn.atb-gradient-btn{
    overflow: hidden;
    color: var(--white);
    border-color: var(--velvet-twilight);
    background-color: transparent;
    position: relative;
    z-index: 0;
}

html[data-theme=light-theme] .alert-text-bx .atb-btn.atb-gradient-btn::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: calc(100% + 10px);
    height: 100%;
    background: radial-gradient(45.74% 210.77% at 51.94% 130.65%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
    z-index: -1;
    transform: translateX(-50%);
    transition: all 0.3s ease-in;
}

html[data-theme=light-theme] .alert-text-bx .atb-btn.atb-gradient-btn:hover::after{
    width: calc(100% + 60px);
}

html[data-theme=light-theme] .alert-text-bx.alert-style4.status-red2 .atb-heading {
    color: var(--red-color) !important;
}
/* --- Alert Message end --- */



/* --- Breadcrumb Start --- */
html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-link:hover,
html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-link.breadcrumb-active,
html[data-theme=light-theme] .breadcrumb-heading-bx {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-sapretor svg,
html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-link {
    color: var(--slate-grey);
}
/* --- Breadcrumb End --- */



/* --- Common Box Start --- */
html[data-theme=light-theme] .common-box.cb-color3,
html[data-theme=light-theme] .common-box.cb-color2,
html[data-theme=light-theme] .common-box {
    box-shadow: 0 0 0 1px var(--black-10);
    background: var(--white);
}

html[data-theme=light-theme] .common-box.cb-all-none {
    box-shadow: none;
    border: none;
    background-color: transparent;
    padding: 0;
}

html[data-theme=light-theme] .common-section-heading,
html[data-theme=light-theme] .common-box-heading {
    color: var(--oxford-blue);
    font-weight: 500;
}

html[data-theme=light-theme] .common-box-text {
    color: var(--slate-grey);
}

html[data-theme=light-theme] .common-section-separator {
    background-color: var(--black-10);
}
/* --- Common Box End --- */


/* --- Common btn start --- */
html[data-theme=light-theme] .common-btn{
    z-index: 0;
}

/* --- --- */
html[data-theme=light-theme] .cb-outline::before{
    background-color: var(--white);
}

html[data-theme=light-theme] .cb-outline:hover::before{
    color: var(--white);
    background-color: var(--adiant-orchid);
}

html[data-theme=light-theme] .cb-outline:hover{
    color: var(--white);
}

/* --- --- */
html[data-theme=light-theme] .cb-fill{
    color: var(--white);
}

html[data-theme=light-theme] .cb-fill:hover{
    color: var(--white);
}

/* --- --- */
html[data-theme=light-theme] .cb-white-fill{
    color: var(--oxford-blue);
    border-color: var(--oxford-blue);
    background-color: var(--oxford-blue-10);
}

html[data-theme=light-theme] .cb-white-fill:hover{
    color: var(--white);
    background-color: var(--oxford-blue);
}
/* --- Common btn end --- */



/* --- Common box info btn start --- */
html[data-theme=light-theme] .common-box-info {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .common-box-info:hover {
    color: var(--adiant-orchid);
}
/* --- Common box info btn end --- */



/* --- Calendar navigation start --- */
html[data-theme=light-theme] .calendar-navigation-bx .cnb-btn{
    color: var(--oxford-blue);
    background-color: var(--white);
    border: 1px solid var(--black-10);
}


@media screen and (min-width:768px) { 
    html[data-theme=light-theme] .calendar-navigation-bx .cnb-btn:hover{
        color: var(--white);
        background-color: transparent;
    }
}

@media screen and (max-width:767px) and (min-width:0px) { 
    html[data-theme=light-theme] .calendar-navigation-bx .cnb-btn:active{
        color: var(--white);
        background-color: transparent;
    }
}
/* --- Calendar navigation end --- */



/* --- Bootsrep modal start --- */
html[data-theme=light-theme] .custom-content .modal-content {
    border: 1px solid var(--white);
    background: var(--white);
}

html[data-theme=light-theme] .custom-modal-header .cmh-lable {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .custom-modal-header .cmh-sub-lable {
    color: var(--oxford-blue-70);
    font-weight: 500;
}
/* --- Bootsrep modal end --- */



/* --- Close btn start --- */
html[data-theme=light-theme] .close-icon {
    color: var(--oxford-blue);
}

@media screen and (min-width:700px) {
    html[data-theme=light-theme] .close-icon:hover {
        background-color: var(--black-10);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    html[data-theme=light-theme] .close-icon:active {
        background-color: var(--black-10);
    }
}
/* --- Close btn end --- */



/* --- Common data bx start --- */
html[data-theme=light-theme] .common-data-bx {
    background-color: var(--ghost-white);
    border: 1px solid var(--pale-periwinkle-gray-50);
}

html[data-theme=light-theme] .common-data-bx .cdb-item {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .common-data-bx .cdb-item .cdb-label {
    color: var(--slate-grey);
    font-weight: 600;
}

html[data-theme=light-theme] .common-data-bx .cdb-item .cdb-data {
    font-weight: 600;
}
/* --- Common data bx end --- */



/* --- Common tabs bx Start --- */
html[data-theme=light-theme] .common-tabs-bx .ctb-item {
    color: var(--oxford-blue);
    z-index: 0;
}

html[data-theme=light-theme] .common-tabs-bx .ctb-item::before {
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .common-tabs-bx .ctb-item:not(.active):hover{
    background-color: var(--black-10);
}
/* --- Common tabs bx end --- */



/* --- Common drop bx start --- */
html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner{
    border: 1px solid var(--black-10);
    background-color: var(--white);
}

html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-track {
    background-color: var(--white);
}

html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb {
    background-color: var(--oxford-blue-10);
}

html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb:hover {
    background-color: var(--oxford-blue);
}
/* --- Common drop bx end--- */



/* --- Common drop data start --- */
html[data-theme=light-theme] .cdi-current{
    border-color: var(--pale-periwinkle-gray-50);
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .cdi-current::before{
    background-color: var(--white);
}

html[data-theme=light-theme] .cdi-item{
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .cdi-item:hover{
    color: var(--oxford-blue);
    background-color: var(--black-10);
}

html[data-theme=light-theme] .cdi-item.cdi-seleted{
    color: var(--white);
    background-color: var(--adiant-orchid);
}

html[data-theme=light-theme] .common-drop-item.active .cdi-current,
html[data-theme=light-theme] .cdi-current:hover{
    border-color: transparent;
}
/* --- Common drop data end --- */



/* --- Chart design start--- */
html[data-theme=light-theme] .Mycharts .MuiChartsLegend-series text,
html[data-theme=light-theme] .Mycharts .MuiChartsAxis-label {
    fill: var(--charcoal-plum) !important;
}

html[data-theme=light-theme] .Mycharts .MuiChartsAxisHighlight-root,
html[data-theme=light-theme] .Mycharts .MuiChartsGrid-horizontalLine{
    stroke: var(--pale-periwinkle-gray-50) !important;
}

html[data-theme=light-theme] .Mycharts .MuiChartsAxis-tickLabel {
    font-weight: 500 !important;
}

html[data-theme=light-theme] .Mycharts .css-1f57y8b {
    fill: var(--charcoal-plum);
    color: var(--charcoal-plum);
}

/* --- --- */

html[data-theme=light-theme] .Mycharts.volume-chart .MuiChartsAxis-tickLabel{
    fill: var(--charcoal-plum) !important;
}
/* --- Chart design end --- */



/* --- Common progress start --- */
html[data-theme=light-theme] .common-progress-data .cpd-label{
    color: var(--oxford-blue);
    font-weight: 600;
}

html[data-theme=light-theme] .common-progress-data .cpd-value {
    color: var(--oxford-blue);
    font-weight: 600;
}

html[data-theme=light-theme] .common-progress-data .cpd-label span,
html[data-theme=light-theme] .common-progress-data .cpd-value span {
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .common-progress-bar {
    background-color: var(--pale-periwinkle-gray-50);
    background-image: repeating-linear-gradient(33deg, transparent 0 4px, var(--white-30) 0 8px);
}
/* --- Common progress end --- */



/* --- Meter chart start --- */
html[data-theme=light-theme] .meter-chart-bx .mcb-meter-pointer{
    filter: invert();
}

html[data-theme=light-theme] .meter-chart-bx .mcb-data-bx .mcb-label{
    font-weight: 600;
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .meter-chart-bx .mcb-data-bx .mcb-value{
    color: var(--oxford-blue);
}
/* --- Meter chart end --- */



/* --- Custome table Start --- */
html[data-theme=light-theme] .custom-table thead {
    background-color: transparent;
}

html[data-theme=light-theme] .custom-table thead tr th {
    color: var(--oxford-blue);
    font-weight: 800;
}

/* Table thead (Sorting icons) start */
html[data-theme=light-theme] .custom-table thead tr th .th-sort-icons {
    color: var(--gunmetal-gray);
}

html[data-theme=light-theme] .custom-table thead tr th:hover .th-sort-icons,
html[data-theme=light-theme] .custom-table thead tr th.active .th-sort-icons {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .custom-table thead tr th.asc .th-sort-icons svg,
html[data-theme=light-theme] .custom-table thead tr th.desc .th-sort-icons svg {
    color: var(--adiant-orchid);
}
/* Table thead (Sorting icons) end */

html[data-theme=light-theme] .custom-table:not(.off-tr-bg) tbody tr {
    border-top-color: var(--black-10);
}

html[data-theme=light-theme] .custom-table:not(.off-tr-bg) tbody tr:hover {
    background-color: var(--black-10);
}

html[data-theme=light-theme] .custom-table tbody tr td {
    font-weight: 500;
    color: var(--charcoal-plum);
}

html[data-theme=light-theme] .custom-table tbody tr td .td-user .user-name{
    color: var(--oxford-blue);
}
/* --- Custome table end --- */



/* --- Pagenation start --- */
html[data-theme=light-theme] .pagination-bx .pb-next,
html[data-theme=light-theme] .pagination-bx .pb-prev{
    color: var(--oxford-blue-70);
    background-color: var(--ghost-white);
    border: 1px solid var(--black-10);
    font-weight: 600;
}

html[data-theme=light-theme] .pagination-bx .pb-next:not(.pb-disabled):hover,
html[data-theme=light-theme] .pagination-bx .pb-prev:not(.pb-disabled):hover{
    color: var(--white);
    border-color: var(--oxford-blue);
    background-color: var(--oxford-blue);
}

html[data-theme=light-theme] .pb-number-bx .pnb-item{
    color: var(--oxford-blue-70);
    font-weight: 600;
    border: 1px solid transparent;
}

html[data-theme=light-theme] .pb-number-bx .pnb-item:not(.pnb-current, .pnb-disabled):hover{
    color: var(--oxford-blue);
    background-color: var(--black-10);
    border-color: var(--black-10);
}

html[data-theme=light-theme] .pb-number-bx .pnb-item.pnb-current{
    color: var(--white);
    background-color: var(--oxford-blue);
    border-color: var(--oxford-blue);
}
/* --- Pagenation end --- */



/* --- Empty box css start ---*/
html[data-theme=light-theme] .empty-div-bx .empty-heading {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .empty-div-bx .empty-sub-heading {
    color: var(--charcoal-plum);
    font-weight: 500;
    opacity: 1;
}
/*--- Empty box css end ---*/



/* --- Notice Start--- */
html[data-theme=light-theme] .legal-content .legal-heading {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .legal-content ul li,
html[data-theme=light-theme] .legal-content p {
    color: var(--oxford-blue-70);
    font-weight: 500;
}
/* --- Notice End --- */


/* --- Cdi Account start --- */
html[data-theme=light-theme] .cdi-account-label{
    font-weight: 500;
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .cdi-account .cdi-current{
    font-weight: 600;
}
/* --- Cdi Account end --- */



/* --- Challenge Card Bx Start --- */
html[data-theme=light-theme] .challenge-card-bx {
    border-color: var(--black-10);
    background: linear-gradient(177.89deg, #F8E7FF 14.11%, #F8E7FF 14.11%, #FFFFFF 104.65%);
}

html[data-theme=light-theme] .challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-data .ccb-i-name {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-data .ccb-i-type {
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .challenge-card-bx .challenge-trade-info .cti-item {
    background-color: var(--white);
}

html[data-theme=light-theme] .challenge-card-bx .challenge-trade-info .cti-item .cti-icon {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .challenge-card-bx .challenge-trade-info .cti-data-bx .cti-label {
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .challenge-card-bx .challenge-trade-info .cti-data-bx .cti-num {
    color: var(--oxford-blue);
}


html[data-theme=light-theme] .ccb-request-info {
    font-weight: 500;
    border-color: var(--pale-periwinkle-gray-50);
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .ccb-request-info.ccb-real-ac {
    color: var(--adiant-orchid);
    border-color: var(--adiant-orchid);
    background-color: var(--adiant-orchid-09)
}
/* --- Challenge card bx end --- */



/* --- Challenge credentials bx start --- */
html[data-theme=light-theme] .challenge-credentials-bx {
    border: 1px solid var(--pale-periwinkle-gray-50);
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .challenge-credentials-bx .credential-item .credential-label {
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .challenge-credentials-bx .credential-item .credential-data {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .challenge-credentials-bx .credential-item .credential-data .credential-icon {
    color: var(--oxford-blue);
    border: 1px solid var(--pale-periwinkle-gray-50);
    background-color: var(--white);
}

html[data-theme=light-theme] .challenge-credentials-bx .credential-item .credential-data .credential-icon:hover {
    background-color: var(--adiant-orchid);
    border-color: var(--adiant-orchid);
    color: var(--white);
}
/* --- Challenge credentials bx end --- */


/* --- Account overview filter start --- */
html[data-theme=light-theme] .signinup-group.sab-dwmy-filter .sg-list-bx{
    background-color: var(--white);
    border-color: var(--white);
}
/* --- Account overview filter end --- */


/* --- Account overview css start --- */
html[data-theme=light-theme] .account-overview-item:not(:last-child):after{
    background-color: var(--pale-periwinkle-gray-50);
}

html[data-theme=light-theme] .account-overview-item .aoi-label {
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .account-overview-item .aoi-data {
    color: var(--oxford-blue);
    font-weight: 600;
}

html[data-theme=light-theme] .account-overview-item .aoi-data span{
    font-weight: 500;
}

/*  */
html[data-theme=light-theme] .aoi-style-2 .aoi-inner .account-overview-item{
    border-color: var(--pale-periwinkle-gray-50);
    background-color: var(--ghost-white);
}
/* --- Account overview css end --- */



/* --- Dashboard page start --- */

/* Dashboard (Welcome bx) start */
html[data-theme=light-theme] .welcome-bx .wb-heading{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .welcome-bx .wb-sub-heading{
    color: var(--slate-grey);
}

html[data-theme=light-theme] .welcome-bx .cb-wb-btn::before{
    background-color: var(--white);
}

html[data-theme=light-theme] .welcome-bx .cb-wb-btn .cb-wb-icon{
    color: var(--white);
    background-color: var(--oxford-blue);;
}

html[data-theme=light-theme] .welcome-bx .cb-wb-btn:hover{
    color: var(--white);
}

html[data-theme=light-theme] .welcome-bx .cb-wb-btn:hover::before{
    background-color: var(--adiant-orchid);
}
/* Dashboard (Welcome bx) end */

/* Dashboard (cb trade performance meter bx) start */
html[data-theme=light-theme] .cb-trade-performance-meter .tpm-info .tpm-i-item{
    color: var(--oxford-blue);
    font-weight: 500;
}
/* Dashboard (cb trade performance meter bx) end */

/* --- Dashboard page end --- */



/* --- Account list bx start --- */
html[data-theme=light-theme] .account-list-box .alb-tabs{
    border-bottom-color: var(--black-10);
}
/* --- Account list bx end --- */



/* --- Account detail page start --- */

/* Account detail (account info bx) start */

html[data-theme=light-theme] .account-info-bx .aib-account-label {
    font-weight: 500;
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .account-info-bx .aib-inner-bx .aib-i-data .aib-i-name {
    color: var(--oxford-blue);
    font-weight: 600;
}

html[data-theme=light-theme] .account-info-bx .aib-inner-bx .aib-i-data .aib-i-created {
    color: var(--slate-grey);
    font-weight: 500;
}
/* Account detail (account info bx) end */


/* Account detail (account basic info) start */
html[data-theme=light-theme] .account-basic-info .abi-item {
    border-top-color: var(--black-10);
}

html[data-theme=light-theme] .account-basic-info .abi-item .abi-label {
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .account-basic-info .abi-item .abi-label svg {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .account-basic-info .abi-item .abi-data {
    color: var(--oxford-blue);
    font-weight: 500;
}
/* Account detail (Account basic info) end */


/* Account detail (Limit Card item) start */

html[data-theme=light-theme] .limit-card-bx .lc-heading {  
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .limit-card-bx .lc-data-bx {
    color: var(--oxford-blue-70);
    font-weight: 600;
}

html[data-theme=light-theme] .limit-card-bx .lc-data-bx .ldb-item .li-data{
    color: var(--oxford-blue);
    font-weight: 600;
}

html[data-theme=light-theme] .limit-card-inner .arc-item .arc-value{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .limit-card-inner .arc-item .arc-other-value{
    color: var(--oxford-blue-70);
    font-weight: 600;
}
/* Account detail (Limit Card item) end */


/* Account detail (Calender bx) start */
html[data-theme=light-theme] .calander-weeks .cw-item{
    color: var(--charcoal-plum);
    font-weight: 600;
}

html[data-theme=light-theme] .calander-days .cd-item{
    color: var(--oxford-blue);
    border-color: var(--pale-periwinkle-gray-50);
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .calander-days .cd-item .cd-i-event{
    font-weight: 500;
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .calander-days .cd-item:not(.cd-i-disabled, .cd-i-seleted):hover{
    background-color: var(--adiant-orchid-09);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-red{
    border-color: var(--coral-red-40);
    background: radial-gradient(186.74% 186.74% at 50% 198.06%, var(--coral-red-50) 0%, transparent 100%);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-red .cd-i-event svg,
html[data-theme=light-theme] .calander-days .cd-item.cd-i-red .cd-i-date{
    color: var(--coral-red);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-green{
    border-color: var(--aquamarine-40);
    background: radial-gradient(186.74% 186.74% at 50% 198.06%, var(--aquamarine-50) 0%, transparent 100%);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-green .cd-i-event svg,
html[data-theme=light-theme] .calander-days .cd-item.cd-i-green .cd-i-date{
    color: var(--aquamarine);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-seleted{
    border-color: var(--adiant-orchid);
    background-color: var(--adiant-orchid-09);
}
/* Account detail (Calender bx) end */


html[data-theme=light-theme] .alert-disclaimer-bx .atb-text{
    color: #F63D56;
}

/* --- Account detail page end --- */



/* --- New Challenge page start --- */


/* --- New Challenge (Challenge form box .common-box) start --- */

html[data-theme=light-theme] .challenge-form-box .cfb-left {
    border-right-color: var(--pale-periwinkle-gray-50);
}

@media screen and (max-width: 768px) {
    html[data-theme=light-theme] .challenge-form-box .cfb-left {
        border-bottom-color: var(--pale-periwinkle-gray-50);
    }
}
/* --- New Challenge (Challenge form box .common-box) end --- */

/* --- New Challenge (Challenge steps bx) start --- */
html[data-theme=light-theme] .challenge-steps-bx .csb-item .csb-i-num {
    color: var(--oxford-blue-70);
    background: var(--ghost-white);
    box-shadow: 0 0 0 1px var(--pale-periwinkle-gray-50);
}

html[data-theme=light-theme] .challenge-steps-bx .csb-item .csb-i-label {
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .challenge-steps-bx .csb-item .csb-progress-bar {
    background-color: var(--pale-periwinkle-gray-50);
}

html[data-theme=light-theme] .challenge-steps-bx .csb-item.complated .csb-i-num,
html[data-theme=light-theme] .challenge-steps-bx .csb-item.active .csb-i-num {
    color: var(--white);
    background: transparent;
    box-shadow:0 0 0 1px var(--velvet-twilight);
}

html[data-theme=light-theme] .challenge-steps-bx .csb-item.complated .csb-i-label,
html[data-theme=light-theme] .challenge-steps-bx .csb-item.active .csb-i-label {
    color: var(--oxford-blue);
}
/* --- New Challenge (Challenge steps bx) end --- */


/* --- New Challenge (Challenge Detail) start --- */
html[data-theme=light-theme] .challenge-detail.common-box.cb-color3{
    overflow: hidden;
    background: linear-gradient(177.89deg, #F8E7FF 14.11%, #F8E7FF 14.11%, #FFFFFF 104.65%);
}

html[data-theme=light-theme] .challenge-detail .cd-top-bx{
    background: linear-gradient(177.89deg, #F8E7FF 14.11%, #F8E7FF 14.11%, #FFFFFF 104.65%);
}

html[data-theme=light-theme] .challenge-detail .cd-trader{
    border: 1px solid var(--adiant-orchid);
    color: var(--adiant-orchid);
    font-weight: 600;
    background-color: var(--adiant-orchid-09);
}

html[data-theme=light-theme] .challenge-detail .cd-amount {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .challenge-detail .cd-amount span{
    font-weight: 400;
}

html[data-theme=light-theme] .challenge-detail .cd-heading {
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .cd-rules-info .cd-r-item {
    color: var(--oxford-blue);
    font-weight: 500;
}

html[data-theme=light-theme] .cd-rules-info .cd-r-item svg {
    color: var(--oxford-blue);
    border: 1px solid var(--oxford-blue);
}

html[data-theme=light-theme] .challenge-detail .cd-more-info {
    font-weight: 500;
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .challenge-detail .cd-more-info a {
    color: var(--oxford-blue);
    font-weight: 600;
}

html[data-theme=light-theme]  .challenge-detail .cd-more-info a:hover {
    color: var(--adiant-orchid);
}
/* --- New Challenge (Challenge Detail) end --- */


/* --- New Challenge (Challenge trading rules) start --- */
html[data-theme=light-theme] .challenge-trading-rules {
    border: 1px solid var(--pale-periwinkle-gray-50);
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .challenge-trading-rules .ctr-item {
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .challenge-trading-rules .ctr-item a {
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .challenge-trading-rules .ctr-item a:hover {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .challenge-trading-rules .ctr-item svg {
    color: var(--oxford-blue);
    border: 1px solid var(--oxford-blue);
}
/* --- New Challenge (Challenge trading rules) end --- */


/* --- New Challenge (Payment method item) end --- */
html[data-theme=light-theme] .payment-method-item {
    border: 1px solid var(--pale-periwinkle-gray-50);
    z-index: 0;
}

html[data-theme=light-theme] .payment-method-item .pmi-data-bx .pmi-heading {
    color: var(--oxford-blue);
    font-weight: 600;
}

html[data-theme=light-theme] .payment-method-item .pmi-data-bx .pmi-pera {
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .payment-method-item:hover {
    border-color: var(--adiant-orchid);
}
/* --- New Challenge (Payment method item) end --- */


/* --- New Challenge (Payment tab bx) start --- */
html[data-theme=light-theme] .payment-tab-item {
    font-weight: 500;
    color: var(--oxford-blue);
    border: 1px solid var(--pale-periwinkle-gray-50);
    z-index: 0;
}

html[data-theme=light-theme] .payment-tab-item .payment-img {
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .payment-tab-item:not(.active):hover {
    border-color: var(--adiant-orchid);
}
/* --- New Challenge (Payment tab bx) end --- */


/* --- New Challenge (Payment data bx) start --- */
html[data-theme=light-theme] .payment-data-bx.pdb-style-3,
html[data-theme=light-theme] .payment-data-bx.pdb-style-2 {
    border: 1px solid var(--pale-periwinkle-gray-50);
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-lable {
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data {
    color: var(--oxford-blue);
    font-weight: 500;
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon {
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon:hover {
    color: var(--adiant-orchid);
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data strong {
    color: var(--adiant-orchid);
    font-weight: 500;
}
/* --- New Challenge (Payment data bx) end --- */


/* --- New Challenge (Payment details) start --- */
html[data-theme=light-theme] .challenge-payment-details .cpd-item {
    border-bottom: 1px solid var(--pale-periwinkle-gray-50);
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item:last-child {
    border-bottom: none;
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item .cpd-title {
    color: var(--oxford-blue);
    font-weight: 500;
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item .cpd-amount {
    color: var(--oxford-blue);
    font-weight: 500;
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item.cpd-gt-item .cpd-title {
    color: var(--oxford-blue);
    opacity: 1;
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item.cpd-gt-item .cpd-amount{
    color: var(--oxford-blue);
    font-weight: 600;
}
/* --- New Challenge (Payment details) end --- */

/* --- New Challenge page end --- */




/* --- Payouts page start --- */

/* Request bx start */
html[data-theme=light-theme] .request-bx{
    background: linear-gradient(177.89deg, #F8E7FF 14.11%, #F8E7FF 14.11%, #FFFFFF 104.65%);
}


html[data-theme=light-theme] .request-bx .atb-heading svg path[fill-opacity="0.5"]{
    fill: #D4D7E0;
}
/* Request bx end */

/* --- Payouts page end --- */



/* --- Leaderboard page start --- */

/* Leaderboard (Best in type) start */
html[data-theme=light-theme] .best-type-bx{
    background: var(--white);
}

html[data-theme=light-theme] .best-type-bx .btb-heading{
    font-weight: 500;
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .best-type-bx .btb-user-bx .btb-name{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .best-type-bx .btb-user-bx .btb-amount{
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .best-type-bx .btb-data{
    color: var(--oxford-blue);
}
/* Leaderboard (Best in type) end */




/* Leaderboard (Best in today) start */

html[data-theme=light-theme] .best-today-item .bti-name{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .best-today-item .bti-type{
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .best-today-row .best-today-item:nth-child(1){
    background: linear-gradient(180deg, #FF01FF 0%, #FFDAFB 100%);
}

html[data-theme=light-theme] .best-today-row .best-today-item:nth-child(2){
    background: linear-gradient(180deg, #00E1FF 0%, #C5E2FF 100%);
}

html[data-theme=light-theme] .best-today-row .best-today-item:nth-child(3){
    background: linear-gradient(180deg, #FFC447 0%, #FFE3D2 100%);
}

html[data-theme=light-theme] .best-today-item .bti-i-item .bii-lable{
    font-weight: 700;
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .best-today-item .bti-i-item .bii-data{
    font-weight: 600;
    color: var(--oxford-blue);
}
/* Leaderboard (Best in today) end */





/* Leaderboard (Best Accounts In Profi) start */
html[data-theme=light-theme] .leaderboard-filter .cdi-current{
    border-color: var(--pale-periwinkle-gray-50);
}


html[data-theme=light-theme] .custom-table.ct-leaderboard thead th{
    color: var(--charcoal-plum);
}

html[data-theme=light-theme] .ct-leaderboard tbody tr .ct-l-bg{
    border: 1px solid var(--adiant-orchid-30);
    background: linear-gradient(177.89deg, #F8E7FF 14.11%, #F8E7FF 14.11%, #FFFFFF 104.65%);
}

html[data-theme=light-theme] .ct-leaderboard tbody tr td{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .ct-leaderboard tbody tr:hover td{
    background-color: var(--black-10);
}
/* Leaderboard (Best Accounts In Profi) end */

/* --- Leaderboard page end --- */



































/* --- Calendar page start --- */

/* Calendar (prediction table) start */

html[data-theme=light-theme] .ct-prediction thead tr th:not(:last-child),
html[data-theme=light-theme] .ct-prediction tbody tr td:not(:last-child){
    border-right: 1px solid var(--pale-periwinkle-gray-50);
}

html[data-theme=light-theme] .ct-prediction thead tr th,
html[data-theme=light-theme] .ct-prediction tbody tr:not(:last-child) td{
    border-bottom: 1px solid var(--pale-periwinkle-gray-50);
}

html[data-theme=light-theme] .ct-prediction thead tr th.th-ctp-time::after,
html[data-theme=light-theme] .ct-prediction tbody tr td.td-ctp-time::after{
    background-color: var(--white);
    border-right: 1px solid  var(--pale-periwinkle-gray-50);
}


html[data-theme=light-theme] .ct-prediction tbody tr td.td-ctp-time .ctp-time{
    color: var(--oxford-blue);
    font-weight: 700;
}




html[data-theme=light-theme] .prediction-card-item:not(.pci-full):hover{
    background-color: var(--black-10);
}

html[data-theme=light-theme] .prediction-card-item .pci-event,
html[data-theme=light-theme] .prediction-card-item .pci-currency{
    font-weight: 600;
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .prediction-card-item .pci-separator{
    background-color: var(--pale-periwinkle-gray-50);
}

html[data-theme=light-theme] .prediction-card-item .pci-data{
    font-weight: 500;
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .prediction-card-item .pci-data span{
    color: var(--oxford-blue);
    font-weight: 600;
}
/* Calendar (prediction table) end */

/* --- Calendar page end --- */



/* --- Affiliate data item start --- */


/* Affiliate (Referral Code) start */
html[data-theme=light-theme] .referral-bx{
    background: linear-gradient(177.89deg, #F8E7FF 14.11%, #F8E7FF 14.11%, #FFFFFF 104.65%);
}

html[data-theme=light-theme] .referral-bx .atb-heading svg path[fill-opacity="0.5"]{
    fill: #D4D7E0;
}

html[data-theme=light-theme] .referral-btn .atb-btn{
    color: var(--oxford-blue);
    border-color: var(--black-10);
    background-color: var(--white);
}

html[data-theme=light-theme] .referral-btn .atb-btn svg{
    color: var(--white);
    background-color: var(--oxford-blue);
}

html[data-theme=light-theme] .referral-btn .atb-btn:hover{
    color: var(--oxford-blue);
    border-color: var(--adiant-orchid);
    background-color: var(--adiant-orchid-09);
}
/* Affiliate (Referral Code) end */


/* Affiliate (affiliate program bx) start */
html[data-theme=light-theme] .affiliate-program-bx .atb-heading{
    font-weight: 500;
}
/* Affiliate (affiliate program bx) end */


/* Affiliate (Data item) start */
html[data-theme=light-theme] .affiliate-data-item .adi-icon{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .affiliate-data-item .adi-icon svg path[fill-opacity="0.5"] {
    fill: #D4D7E0;
}

html[data-theme=light-theme] .affiliate-data-item{
    border-color: var(--black-10);
    background-color: var(--white);
}

html[data-theme=light-theme] .affiliate-data-item .adi-heading{
    color: var(--charcoal-plum);
    font-weight: 600;
}

html[data-theme=light-theme] .affiliate-data-item .adi-data{
    color: var(--oxford-blue);
}
/* Affiliate (Data item) end */


/*  */
html[data-theme=light-theme] .affiliate-balance{
    border: 1px solid var(--pale-periwinkle-gray-50);
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .affiliate-balance .ab-balance,
html[data-theme=light-theme] .affiliate-balance .ab-name{
    color: var(--slate-grey);
    font-weight: 500;
    opacity: 1;
}

html[data-theme=light-theme] .affiliate-balance .ab-balance{
    color: var(--adiant-orchid);
    font-weight: 600;
}
/*  */

/* --- Affiliate page end --- */


/* --- Settings page start --- */
@media screen and (min-width:769px) {
    html[data-theme=light-theme] .settings-bx .ctb-settings{
        border-right: 1px solid var(--black-10);
    }
}

@media screen and (max-width:768px) and (min-width:0) {
    html[data-theme=light-theme] .settings-bx .ctb-settings{
        border-bottom: 1px solid var(--black-10);
    }
}
/* --- Settings page end --- */

/* --- Style css end ---- */



/* --- Commonform css start --- */
html[data-theme=light-theme] ::placeholder{
    color: var(--charcoal-plum);
}


/* --- Extra label start --- */
html[data-theme=light-theme] .sgr-outer-label{
    color: var(--oxford-blue);
    font-weight: 500;
}
/* --- Extra label end --- */



html[data-theme=light-theme] .signinup-group select,
html[data-theme=light-theme] .signinup-group textarea,
html[data-theme=light-theme] .signinup-group input {
    color: var(--oxford-blue);
    border-color: #dce4f1;
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .signinup-group select option{
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .signinup-group textarea:focus,
html[data-theme=light-theme] .signinup-group select:focus,
html[data-theme=light-theme] .signinup-group input:focus {
    border-color: var(--adiant-orchid);
    box-shadow: 0 0 0px 0.1px var(--adiant-orchid);
}

html[data-theme=light-theme] .signinup-group .group_right_icon,
html[data-theme=light-theme] .signinup-group .group_left_icon{
    color: var(--oxford-blue);
}

/* --- Error input color start --- */
html[data-theme=light-theme] .signinup-group.error-group textarea,
html[data-theme=light-theme] .signinup-group.error-group select,
html[data-theme=light-theme] .signinup-group.error-group input{
    border-color: var(--red-color);
    box-shadow: 0 0 0px 0.1px var(--red-color);
}
/* --- Error input color end --- */


/* --- Form error msg start --- */
html[data-theme=light-theme] .form-error-msg{
    font-weight: 500;
}
/* --- Form error msg end --- */



/* --- Basic input note start --- */
html[data-theme=light-theme] .input-note{
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .input-note span{
    color: var(--oxford-blue);
    font-weight: 600;
}

html[data-theme=light-theme] .input-note svg,
html[data-theme=light-theme] .input-note span:hover{
    color: var(--adiant-orchid);
}
/* --- Basic input note end --- */


/* --- Remember input checkbox start --- */
html[data-theme=light-theme] .signinup-group-checkmark label{
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .signinup-group-checkmark:not(.sgc-ci-hover-off) label:hover{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .signinup-group-checkmark label a{
    color: var(--black-70);
}

html[data-theme=light-theme] .signinup-group-checkmark label a:hover{
    color: var(--black);
}

html[data-theme=light-theme] .signinup-group-checkmark label .checkmark-icon{
    border-color: #dce4f1;
    background-color: var(--white);
}

html[data-theme=light-theme] .signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--adiant-orchid);
    box-shadow: 0 0 0px 0.1px var(--adiant-orchid);
}

html[data-theme=light-theme] .signinup-group-checkmark input:checked + label{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .signinup-group-checkmark input:checked + label .checkmark-icon{
    border-color: var(--adiant-orchid);
    background-color: var(--adiant-orchid);
}
/* --- Remember input checkbox start --- */


/* --- login & submit btn start --- */
html[data-theme=light-theme] .common-submit-btn,
html[data-theme=light-theme] .lsb-loader-btn{
    color: var(--white);
    background-color: var(--oxford-blue);
}

html[data-theme=light-theme] .lsb-loader-btn,
html[data-theme=light-theme] .common-submit-btn:not([disabled]):hover{
    color: var(--white);
    background-color: var(--adiant-orchid);
}
/* --- login & submit btn end --- */


/* --- ---- */
html[data-theme=light-theme] .signinup-group .sg-list-bx{
    background-color: var(--ghost-white);
    border-color: var(--ghost-white);
}

html[data-theme=light-theme] .signinup-group .sg-list-bx .sg-list-item{
    color: var(--oxford-blue-70);
    font-weight: 600;
}

html[data-theme=light-theme] .signinup-group .sg-list-bx .sg-list-item:hover{
    color: var(--oxford-blue);
    background-color: var(--black-10);
}

html[data-theme=light-theme] .signinup-group .sg-list-bx .sg-list-item.active{
    color: var(--white);
    border-color: var(--adiant-orchid);
}

html[data-theme=light-theme] .signinup-group .sg-list-bx .sg-list-item.active::after{
    opacity: 1;
}

/* -=-=-=-=-=-=-=-=- */

html[data-theme=light-theme] .signinup-group .sg-list-bx.slb-style-2{
    background-color: transparent;
}

html[data-theme=light-theme] .signinup-group .sg-list-bx.slb-style-2 .sg-list-item{
    border-color: var(--pale-periwinkle-gray-50);
}
/* --- --- */


/* --- Input autofill start --- */
html[data-theme=light-theme] .signinup-group select:-webkit-autofill,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill:hover,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill:focus,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill:active,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill:hover,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill:focus,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill:active {
    caret-color: var(--oxford-blue) !important;
    color: var(--oxford-blue) !important;
    border-color: var(--black-10) !important;
    -webkit-text-fill-color: var(--oxford-blue) !important;
    -webkit-box-shadow: inset 0 0 0 100px var(--ghost-white) !important;
            box-shadow: inset 0 0 0 100px var(--ghost-white) !important;
}
/* --- Input autofill end --- */



/* --- --- */
html[data-theme=light-theme] .signinup-width .signinup-group select,
html[data-theme=light-theme] .signinup-width .signinup-group textarea,
html[data-theme=light-theme] .signinup-width .signinup-group input{
    background-color: var(--white);
}


html[data-theme=light-theme] .signinup-width .signinup-group select:-webkit-autofill,
html[data-theme=light-theme] .signinup-width .signinup-group select:-webkit-autofill:hover,
html[data-theme=light-theme] .signinup-width .signinup-group select:-webkit-autofill:focus,
html[data-theme=light-theme] .signinup-width .signinup-group select:-webkit-autofill:active,
html[data-theme=light-theme] .signinup-width .signinup-group input:-webkit-autofill,
html[data-theme=light-theme] .signinup-width .signinup-group input:-webkit-autofill:hover,
html[data-theme=light-theme] .signinup-width .signinup-group input:-webkit-autofill:focus,
html[data-theme=light-theme] .signinup-width .signinup-group input:-webkit-autofill:active {
    -webkit-box-shadow: inset 0 0 0 100px var(--white) !important;
            box-shadow: inset 0 0 0 100px var(--white) !important;
}
/* --- --- */



/* --- signinup-group-profile Start ---  */
html[data-theme=light-theme] .signinup-group-profile .sgp-lable{
    border-color: #dce4f1;
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .signinup-group-profile .sgp-lable.dragover,
html[data-theme=light-theme] .signinup-group-profile .sgp-lable:hover{
    border-color: var(--adiant-orchid);
}


html[data-theme=light-theme] .signinup-group-profile .sgp-btn{
    color: var(--oxford-blue);
    border-color: #dce4f1;
    background-color: var(--ghost-white);
}


html[data-theme=light-theme] .signinup-group-profile .sgp-btn.sgp-edit:hover{
    background-color:var(--adiant-orchid);
}

html[data-theme=light-theme] .signinup-group-profile .sgp-btn.sgp-delete:hover{
    background-color: var(--coral-red);
}

/* --- signinup-group-profile End ---  */


/* --- Common file upload start --- */
html[data-theme=light-theme] .fileupload{
    border-color: var(--black-10);
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .fileupload .feud-lable{
    color: var(--oxford-blue-70);
    font-weight: 500;
}

html[data-theme=light-theme] .fileupload .feud-sub-lable{
    font-weight: 500;
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .fileupload .feud-lable span{
    color: var(--adiant-orchid);
}

html[data-theme=light-theme] .fileupload.dragover,
html[data-theme=light-theme] .fileupload:hover{
    border-color: var(--adiant-orchid);
    box-shadow: 0 0 0px 0.1px var(--adiant-orchid);
}

html[data-theme=light-theme] .fileupload:hover .feud-lable{
    color: var(--oxford-blue);
}
/* --- Common file upload end --- */


/* --- Common file upload item start --- */
html[data-theme=light-theme] .upload-document-item{
    border: 1px solid var(--black-10);
    background-color: var(--ghost-white);
}

html[data-theme=light-theme] .upload-document-item .udi-img-bx{
    background-color: var(--white);
}

html[data-theme=light-theme] .upload-document-item .udi-data-bx .udi-data-name{
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .upload-document-item .udi-data-bx .udi-data-type-size{
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .upload-document-item .udi-icon-bx{
    color: var(--oxford-blue-70);
}
/* --- Common file upload item end --- */

/* --- Commonform css end --- */



/* --- signinup start --- */

/* --- Smb content (Screen heading) start --- */
html[data-theme=light-theme] .screen-hed {
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .screen-pera {
    color: var(--oxford-blue-70);
}
/* --- Smb content (Screen heading) start --- */


/* --- --- */
html[data-theme=light-theme] .signinup-link-outer-bx{
    font-weight: 500;
    color: var(--oxford-blue-70);
}

html[data-theme=light-theme] .signinup-link-two{
    font-weight: 500;
    color: var(--oxford-blue);
}

html[data-theme=light-theme] .signinup-link-two:hover{
    color: var(--oxford-blue);
    text-decoration: underline;
}

/* --- signinup end --- */