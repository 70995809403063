/* --- Side Menu Bx Css Start --- */
.side-menu-bx{
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 126;
    width: 240px;
    height: calc(100% - 20px);
    background-color: var(--midnight-carbon-full);
    border-radius: 20px;
    border: 1px solid var(--white-10);
    transition: 0.3s ease-in-out;
    overflow: hidden;
}

.side-menu-bx.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}


/* --- */
.side-menu-bx .smb-header{
    position: sticky;
    top: 0;
    z-index: 1;
    height: 70px;
    padding: 16px 16px;
    background-color: var(--midnight-carbon-full);
}


.side-menu-bx .smb-header .logo-bx.smb-logo{
    height: 100%;
    justify-content: inherit;
}

.side-menu-bx .smb-header .logo-bx.smb-logo .logo-icon{
    position: relative;
    right: 0;
    width: 25px;
    height: 25px;
}

.side-menu-bx .smb-header .logo-bx.smb-logo .logo-text{
    height: 22px;
}
/* --- */


/* --- */
.smb-menu-bx{
    position: absolute;
    top: 0;
    padding: 16px;
    padding-top: 2px;
    height: calc(100% - 70px);
    max-height: calc(100% - 70px);
    width: 100%;
    margin-top: 70px;
    overflow: auto;
    transition: .3s ease-in-out;
}

.smb-menu-bx::-webkit-scrollbar{
    display: none;
}

.smb-menu-bx.smb-separator{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.side-menu-bx .smb-link{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    color: var(--lavender-mist);
    font-weight: 300;
    font-size: 14px;
    padding: 0px 11px;
    margin-bottom: 5px;
    height: 40px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    letter-spacing: 1px;
}

.side-menu-bx .smb-link .smb-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
}

.side-menu-bx .smb-link .smb-icon svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.side-menu-bx .smb-link .smb-text .smb-icon{
    position: relative;
    right: -4px;
    width: 26px;
    height: 26px;
    flex: 0 0 26px;
    padding: 6px;
    color: var(--deep-eclipse);
    background-color: var(--white);
    border-radius: 50%;
}

.side-menu-bx .smb-link .smb-text .smb-icon svg{
    stroke-width: 3px;
}

.side-menu-bx .smb-link .smb-text{
    white-space: nowrap;
    position: relative;
    top: -0px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
}

.side-menu-bx .smb-link:not(.highlight-link):hover{
    background-color: var(--shadow-grey);
}

.side-menu-bx .smb-link:not(.highlight-link).active{
    color: var(--white);
    background-color: var(--velvet-twilight);
}

.side-menu-bx .smb-link.highlight-link{
    color: var(--white);
    box-shadow: 0 0 0 1px var(--white-10);
    overflow: hidden;
}

.side-menu-bx .smb-link.highlight-link::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(45.74% 210.77% at 51.94% 130.65%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
    z-index: -1;
    transform: translateX(-50%);
    transition: all 0.3s ease-in;
}

.side-menu-bx .smb-link.highlight-link.active::after,
.side-menu-bx .smb-link.highlight-link:hover::after{
    width: calc(100% + 60px);
}

.side-menu-bx .smb-link-separator{
    height: 15px;
}
/* --- */

/* --- Side Menu Bx Css End --- */


/* --- Side Menu Overlay Start --- */
.side-menu-overlay{
    position: fixed;
    left: 0;
    top: 0;
    inset: 0;
    z-index: 125;
    background-color: var(--black-30);
    backdrop-filter: blur(5px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}
/* --- Side Menu Overlay End --- */



/* --- side menu min css start --- */
@media screen and (min-width:1200px) {

    .side-menu-bx.smb-mini:hover{
        z-index: 128;
    }

    .side-menu-bx.smb-mini:not(:hover){
        width: 75px;
    }

    .side-menu-bx.smb-mini:not(:hover) .smb-header .logo-bx.smb-logo .logo-icon{
        right: -9px;
    }

    .side-menu-bx.smb-mini:not(:hover) .smb-header .logo-bx.smb-logo .logo-text,
    .side-menu-bx.smb-mini:not(:hover) .smb-link .smb-text{
        opacity: 0;
        visibility: hidden;
    }

    .side-menu-bx.smb-mini .smb-link.highlight-link{
        justify-content: flex-start;
    }
}
/* --- side menu min css end --- */

/* --- side menu min css end --- */
@media screen and (max-width:1200px) and (min-width:0px) {
    .side-menu-bx{
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
    }

    .side-menu-bx.active {
        opacity: 1;
        visibility: visible;
        transform: translateX(0%);
    }

    .side-menu-overlay.active{
        opacity: 1;
        visibility: visible;
    }
}
/* --- side menu min css end --- */