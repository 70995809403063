/* --- --- */
.pattern-bx{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% - 70px);
    overflow: hidden;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
}

.pattern-bx.pb-full{
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 1200px) {   
    .pattern-bx{
        width: calc(100% - 240px);
    }
    .custome-wrapper.cw-smb-mini .pattern-bx{
        width: calc(100% - 75px);
    }
}
/* --- --- */

/* --- --- */
.star-pattern{
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
/* --- --- */


/* --- --- */
.gradient-pattern{
    pointer-events: none;
    position: absolute;
    width: 140px;
    height: 140px;
    object-position: center;
    object-fit: cover;
    filter: blur(80px);
    background: radial-gradient(143.63% 197.29% at 50% 59.68%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

.gradient-pattern.gp-left{
    left: -70px;
    bottom: -70px;
}

.gradient-pattern.gp-right{
    right: -70px;
    top: -70px;
}

@media screen and (max-width:768px) {
    .gradient-pattern{
        display: none;
    }
}
/* --- --- */