.custom-header{
    padding: 10px 10px;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 125;
    /* background-color: var(--deep-eclipse); */
}

.custom-header .ch-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    border-radius: 50px;
    padding: 0 12px;
    border: 1px solid var(--white-10);
    background-color: var(--midnight-carbon-full);
}

.custom-header .ch-inner .ch-left{
    display: flex;
    gap: 15px;
}

.custom-header .ch-inner .ch-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

@media screen and (max-width:768px) {
    .custom-header {
        background-color: var(--deep-eclipse);
        border-bottom: 1px solid var(--white-10);
    }

    .custom-header .ch-inner{
        padding: 0;
        border: none;
        background-color: transparent;
        height: 60px;
    }
}

@media screen and (max-width:380px) {
    .custom-header .ch-inner .ch-left {
        gap: 10px;
    }
}


/* --- Header (Logo Start) --- */
.custom-header .ch-inner .ch-left .ch-logo-bx{
    display: none;
}

@media screen and (max-width:1200px) {
    .custom-header .ch-inner .ch-left .ch-logo-bx{
        display: flex;
    }
}

@media screen and (max-width:576px) {
    /* .custom-header .ch-inner .ch-left .ch-logo-bx{
        transform: scale(0.9);
    } */
    .custom-header .ch-inner .ch-left .ch-logo-bx .logo-icon{
        width: 23px;
        height: 23px;
    }
    .custom-header .ch-inner .ch-left .ch-logo-bx .logo-text{
        height: 21px;
    }
}

@media screen and (max-width:390px) {
    .custom-header .ch-inner .ch-left .ch-logo-bx .logo-text{
        display: none;
    }
}
/* --- Header (Logo End) --- */


/* --- Header (theme mode btn start) --- */
.cr-theme-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.cr-theme-btn .cr-tb-lable{
    color: var(--white);
    font-size: 14px;
    letter-spacing: 1px;
    position: relative;
    top: -1px;
}

@media screen and (max-width:768px) {
    .cr-theme-btn.ctb-sch-btn .cr-tb-lable{
        display: none;
    }
}

@media screen and (max-width:576px) {
    .cr-theme-btn .cr-tb-lable{
        display: none;
    }
}
/* --- Header (theme mode btn end) --- */


/* --- Header (User info bx start) --- */
@media screen and (max-width:576px) {
    .user-info-bx.cr-uib{
        padding: 5px
    }

    .user-info-bx.cr-uib .uib-img{
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
    }

    .cr-uib .uib-data{
        display: none;
    }
}
/* --- Header (User info bx end) --- */


/* --- Header (cr icons bx start) --- */
.cr-icons-link{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    flex: 0 0 44px;
    padding: 10px;
    color: var(--white);
    background-color: var(--shadow-grey);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
}

.cr-icons-link .cr-num{
    position: absolute;
    right: 10px;
    top: 9px;
    width: 10px;
    height: 10px;
    font-size: 10px;
    font-weight: 500;
    border-radius: 50px;
    border: 2px solid var(--charcoal-grey);
    background: var(--red-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cr-drop-item:hover > .cr-icons-link,
.cr-icons-link:hover{
    color: var(--white);
    background-color: var(--velvet-twilight);
}

@media screen and (max-width:576px) {
    .cr-icons-link{
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
    }
}
/* --- Header (Cr icons bx end) --- */


/* --- Header (Aside menu icon start) --- */
.menu-icon-bx {
    width: 44px;
    height: 44px;
    flex: 0 0 44px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--shadow-grey);
}

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 2.2px;
    border-radius: 100px;
    opacity: 0.8;
    background-color: var(--slate-grey);
    transition: 0.3s ease-in-out;
}

.menu-icon-bx span:nth-child(2) {
    width: 60%;
    margin-right: auto;
}


.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
    background-color: var(--white);
}

.menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
}

@media screen and (max-width:576px) {
    .menu-icon-bx{
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        padding: 10px;
    }
}
/* --- Header (Aside menu icon end) --- */


/* --- Header (Smb min btn start) --- */
.smb-min-btn{
    display: none;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    padding: 7px;
    border-radius: 50%;
    color: var(--white);
    background-color: var(--shadow-grey);
    border: 1px solid var(--white-10);
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.smb-min-btn:hover{
    background-color: var(--velvet-twilight);
    border-color: var(--velvet-twilight);
}

.smb-min-btn svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    stroke-width: 3px;
    transition: transform 0.3s ease-in-out;
}
/* --- Header (Smb min btn start) --- */


/* --- side menu min css start --- */
@media screen and (min-width:1200px) {
    .custom-header{
        margin-left: auto;
        width: calc(100% - 240px - 15px);
        transition: width 0.3s ease-in-out;
        z-index: 127;
    }

    .custom-header.cw-smb-mini{
        width: calc(100% - 75px - 15px);
    }

    .custom-header .smb-min-btn{
        display: flex;
    }

    .custom-header.cw-smb-mini .smb-min-btn svg{
        transform: rotate(180deg);
    }

    .menu-icon-bx {
        display: none;
    }
}
/* --- side menu min css end --- */