/* --- Web Loader Css --- */
.web-preloader {
    background-color: var(--deep-eclipse);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000;
}

.web-preloader span.web-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
}

.web-preloader span.web-inner img {
    position: relative;
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: center;
}

.deactivate span.web-inner {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s 0.5s ease-in-out;
}

.deactivate {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s 1s ease-in-out;
}