.signinup-main-box{
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 1440px;
    position: relative;
    z-index: 1;
    min-height: 100vh;
}


/* --- Smb side img start --- */
.signinup-main-box .smb-side-img{
    width: auto;
    height: 100vh;
    object-position: center;
    object-fit: contain;
    padding: 15px;
    position: sticky;
    top: 0;
}
/* --- Smb side img end --- */


/* --- Smb content box start --- */
.signinup-main-box .smb-content-box{
    flex-grow: 1;
    max-width: 64%;
}

.signinup-main-box .smb-content-box .smb-content-wraper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 0px);
}

/* --- Smb content (signinup header) start --- */
.signinup-header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
}
/* --- Smb content (signinup header) end  --- */


/* --- --- */
.signinup-screen {
    max-width: 500px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 15px;
}

/* --- Screen heading --- */
.screen-header{
    margin-bottom: 24px;
}

.screen-hed {
    color: var(--white);
    font-weight: 600;
    font-size: 32px;
    text-align: center;
}

.screen-pera {
    color: var(--slate-grey);
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 4px;
}


/* --- --- */
.signinup-link-outer-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: var(--slate-grey);
}

.slob-fdc{
    gap: 1px;
    flex-direction: column;
    justify-content: center;
}

.slob-fdc.fdc-two{
    gap: 5px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.signinup-link-two{
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: var(--white-70);
    max-width: fit-content;
}

.signinup-link-two:hover{
    color: var(--white);
    text-decoration: underline;
}


@media screen and (max-width:768px) {
    .signinup-main-box{
        flex-direction: column-reverse;
        justify-content: inherit;
        gap: 25px;
    }

    .signinup-main-box .smb-side-img{
        max-width: 500px;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    .signinup-main-box .smb-content-box{
        max-width: 100%
    }
    
}