:root {
    --white: #ffffff;
    --white-80: #ffffffcc;
    --white-70: #ffffffb3;
    --white-60: #ffffff66;
    --white-30: #ffffff4d;
    --white-20: #ffffff33;
    --white-20: #ffffff33;
    --white-15: #ffffff26;
    --white-10: #ffffff1a;
    --white-05: #ffffff0d;
    
    --black: #000000;
    --black-30: #0000004d;
    --black-10: #0000000d;

    --deep-eclipse: #040607;

    --midnight-carbon-full: #0e1011;
    --midnight-carbon: #1D1F2066;

    --shadow-grey: #2B2933;
    --velvet-twilight: #3B2A45;

    --adiant-orchid: #C12EFC;
    --adiant-orchid-60: #c12efc66;
    --adiant-orchid-50: #c22efc80;
    --adiant-orchid-30: #c12efc4d;
    --adiant-orchid-20: #c12efc33;
    --adiant-orchid-15: #c22efc26;
    --adiant-orchid-09: #c12efc17;

    --obsidian-mist: #151419;
    --obsidian-mist-50: #15141980;
    --obsidian-mist-20: #15141933;
    --obsidian-mist-15: #15141926;
    --obsidian-mist-10: #1514191a;

    --neon-fuchsia: #FF00DD;

    --charcoal-grey: #2E2C33;
    --eclipse-gray: #212027;
    --dark-purple: #200C2B;
    --royal-plum: #390B4B;
    --midnight-violet: #1D0427;
    --lavender-stone: #7B5F8C;
    --lavender-mist: #eaedf2;
    --nocturne-violet: #20062B;
    --velvet-twilight: #3B2A45;
    --amethyst-haze: #9226be4d;
    --smoky-mulberry: #322636;
    --slate-grey: #888E9E;
    --gunmetal-gray: #677075;

    --ghost-white: #F6F8FA;
    --charcoal-plum: #4C4A54;

    --oxford-blue: #141B34;
    --oxford-blue-70: #141B34b3;
    --oxford-blue-10: #141B341a;
    
    --pale-periwinkle-gray: #D4D7E0;
    --pale-periwinkle-gray-50: #D4D7E080;
    
    --neon-green: #85FF67;

    --red-color: #F63D68;
    --red-color-10: #F63D681a;

    --red2-color: #FFE4E8;
    --red3-color: #FFB7C8;

    --green-color: #16B364;
    --green-color-10: #16B3641a;


    --green2-color: #D3F8DF;
    --green3-color: #2F534A;
    --green4-color: #BFE1D8;

    --aquamarine: #39CBA2;
    --aquamarine-50: #39CBA280;
    --aquamarine-40: #39CBA266;

    --coral-red: #F63D56;
    --coral-red-50: #F63D5680;
    --coral-red-40: #F63D5666;
    --coral-red-30: #F63D564d;

    --yellow-color: #CA8504;
    --yellow2-color: #FEF7C3;
}