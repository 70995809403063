/* --- Common field update start --- */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    display: none;
}

::placeholder{
    font-size: 14px;
    color: var(--white-60);
    font-weight: 400;
    font-family: "Inter", sans-serif;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
/* --- Common field update end --- */


/* --- Extra label start --- */
.sgr-outer-label{
    color: var(--white);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
}

.sgr-outer-label.sol-star::after{
    content: "*";
    margin-left: 5px;
    position: relative;
    top: 0px;
    font-size: 14px;
    color: var(--adiant-orchid);
}
/* --- Extra label end --- */


.signinup-group {
    position: relative;
    margin: 0px 0 18px;
}


.signinup-group select,
.signinup-group textarea,
.signinup-group input {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    display: block;
    color: var(--white);
    border: 0;
    font-size: 14px;
    border: 1px solid var(--white-20);
    background-color: var(--white-05);
    width: 100%;
    height: 44px;
    line-height: 44px;
    outline: none;
    padding: 0px 12px;
    border-radius: 50px;
    transition: all 0.3s ease;
}

.signinup-group select option{
    background-color: var(--midnight-carbon-full);
}

.gopageinput.signinup-group input{
    height: auto;
    padding: 4px 3px;
    width: 46px;
    text-align: center;
}

.signinup-group select[disabled],
.signinup-group textarea[disabled],
.signinup-group input[disabled] {
    cursor: not-allowed;
    pointer-events: none;
}

.signinup-group textarea{
    min-height: 80px;
}

.signinup-group.sg-150-textarea textarea{
    min-height: 150px;
}


/* .signinup-group:not(.error-group) select:hover,
.signinup-group:not(.error-group) textarea:hover,
.signinup-group:not(.error-group) input:hover{
    border-color: var(--black);
    box-shadow: 0 0 0 0.1px var(--black);
} */

/* .signinup-group textarea:not(:placeholder-shown), */
.signinup-group textarea:focus,
/* .signinup-group select:not(:placeholder-shown), */
.signinup-group select:focus,
/* .signinup-group input:not(:placeholder-shown), */
.signinup-group input:focus {
    border-color: var(--adiant-orchid);
    box-shadow: 0 0 0 0.1px var(--adiant-orchid);
}



.signinup-group.sgl-icon select,
.signinup-group.sgl-icon input{
    padding-left: 44px;
}

.signinup-group.sgr-icon select,
.signinup-group.sgr-icon input{
    padding-right: 44px;
}

.signinup-group .group_right_icon,
.signinup-group .group_left_icon{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    font-size: 14px;
    color: var(--white);
    font-weight: 500;
}


.signinup-group .group_right_icon,
.signinup-group .group_left_icon{
    position: absolute;
    top: 0;
}

.signinup-group .group_left_icon{
    left: 0;
}

.signinup-group .group_right_icon{
    right: 0;
}

.signinup-group .group_right_icon svg,
.signinup-group .group_left_icon svg{
    height: 20px;
    width: 20px;
    opacity: .7;
    transition: all .3s ease-in-out;
}

.signinup-group .group_left_icon svg{
    opacity: 1;
}

.signinup-group .group_right_icon.gri-select-icon{
    pointer-events: none;
}

.signinup-group .group_right_icon.gri-select-icon svg{
    height: 16px;
    width: 16px;
}

.signinup-group:hover .group_right_icon.gri-select-icon svg,
.signinup-group .group_right_icon:hover svg{
    opacity: 1;
}



/* --- Error input color start --- */
.signinup-group.error-group textarea,
.signinup-group.error-group select,
.signinup-group.error-group input{
    border-color: var(--red-color);
    box-shadow: 0 0 0 0.1px var(--red-color);
}
/* --- Error input color end --- */



/* --- Form error msg start --- */
.form-error-msg{
    display: block;
    text-align: right;
    color: var(--red-color);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: -5px;
    margin-top: 8px;
    letter-spacing: 0.8px;
}

.form-error-msg .fa{
    margin-right: 5px;
}

.form-error-msg::before{
    display: none;
    content: "\f071";
    font: normal normal normal 14px/1 FontAwesome;
    margin-right: 5px;
    font-size: 10px;
}
/* --- Form error msg end --- */



/* --- Basic input note start --- */
.input-note{
    font-weight: 400;
    color: var(--slate-grey);
    font-size: 14px;
    margin-top: 10px;
}

.input-note span{
    cursor: pointer;
    font-weight: 500;
    color: var(--white);
    transition: all 0.3s ease-in-out;
}

.input-note span:hover{
    text-decoration: underline;
}

.input-note svg{
    width: 24px;
    height: 24px;
    position: relative;
    top: -1.5px;
    color: var(--adiant-orchid);
}
/* --- Basic input note end --- */


/* --- Remember input checkbox start --- */
.signinup-group-checkmark input{
    display: none;
}

.signinup-group-checkmark{
    margin-bottom: 13px;
    position: relative;
    z-index: 3;
}

.signinup-group-checkmark label{
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    font-size: 14px;
    color: var(--white-70);
    font-weight: 400;
    transition: all 0.3s ease-in-out;
}


.signinup-group-checkmark:not(.sgc-ci-hover-off) label:hover{
    color: var(--white);
}

.signinup-group-checkmark label a{
    color: var(--white-70);
    text-decoration: underline;
}

.signinup-group-checkmark label a:hover{
    color: var(--white);
}

.signinup-group-checkmark label .checkmark-icon{
    cursor: pointer;
    width: 17px;
    height: 17px;
    display: inline-block;
    border-radius: 4px;
    background-position: center;
    border: 1px solid var(--white-20);
    background-color: var(--white-05);
    box-shadow: 0 0 0px 4px transparent inset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: calc(100% - 2px);
    transition: 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signinup-group-checkmark.sgc-ci-18px label{
    font-size: 13px;
}
.signinup-group-checkmark.sgc-ci-18px label .checkmark-icon{
    width: 18px;
    height: 18px;
}

.signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--adiant-orchid);
    box-shadow: 0 0 0px 0.1px var(--adiant-orchid);
}

.signinup-group-checkmark input:checked + label{
    color: var(--white);
}

.signinup-group-checkmark input:checked + label .checkmark-icon{
    border-color: var(--adiant-orchid);
    background-color: var(--adiant-orchid);
    box-shadow: none;
    background-image: url(../img/icons/check.svg);
}
/* --- Remember input checkbox start --- */


/* --- Phone number input start --- */
.sg-country-code .PhoneInputCountry {
    height: 44px;
    left: 10px;
    position: absolute;
    top: 0;
}

.PhoneInputCountryIcon {
    height: 1em;
    width: calc(1em * 1.5);
}

.PhoneInputCountrySelect {
    border: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.PhoneInputCountryIconImg {
    display: block;
    height: 100%;
    width: 100%;
}

.sg-country-code .PhoneInputCountrySelectArrow {
    border-width: 0 1.5px 1.5px 0;
    left: 3px;
    padding: 3px;
    position: relative;
    top: -2px;
}

.sg-country-code input {
    padding-left: 58px;
}

.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
}

.PhoneInputCountrySelectArrow {
    border-bottom-width: 1px;
    border-color: currentColor;
    border-left-width: 0;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 0;
    content: "";
    display: block;
    height: .3em;
    margin-left: .35em;
    opacity: .45;
    transform: rotate(45deg);
    width: .3em;
}

.PhoneInputCountry {
    align-items: center;
    align-self: stretch;
    display: flex;
    margin-right: .35em;
    position: relative;
}
/* --- Phone number input start --- */



/* --- Input autofill start --- */
.signinup-group select:-webkit-autofill,
.signinup-group select:-webkit-autofill:hover,
.signinup-group select:-webkit-autofill:focus,
.signinup-group select:-webkit-autofill:active,
.signinup-group input:-webkit-autofill,
.signinup-group input:-webkit-autofill:hover,
.signinup-group input:-webkit-autofill:focus,
.signinup-group input:-webkit-autofill:active {
    font-weight: 400 !important;
    font-size: 14px !important;
    caret-color: var(--white) !important;
    color: var(--white) !important;
    border-color: #404242 !important;
    -webkit-text-fill-color: var(--white) !important;
    -webkit-box-shadow: inset 0 0 0 100px #221629 !important;
            box-shadow: inset 0 0 0 100px #221629 !important;
    background: transparent !important;
    transition: none !important;
}

.signinup-width .signinup-group select:-webkit-autofill,
.signinup-width .signinup-group select:-webkit-autofill:hover,
.signinup-width .signinup-group select:-webkit-autofill:focus,
.signinup-width .signinup-group select:-webkit-autofill:active,
.signinup-width .signinup-group input:-webkit-autofill,
.signinup-width .signinup-group input:-webkit-autofill:hover,
.signinup-width .signinup-group input:-webkit-autofill:focus,
.signinup-width .signinup-group input:-webkit-autofill:active {
    -webkit-box-shadow: inset 0 0 0 100px #111314 !important;
            box-shadow: inset 0 0 0 100px #111314 !important;
}
/* --- Input autofill end --- */



/* --- login & submit btn start --- */
.common-submit-btn,
.lsb-loader-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 15px;
    border-radius: 50px;
    width: 100%;
    font-weight: 500;
    color: var(--deep-eclipse);
    font-size: 16px;
    margin: 0 auto 13px;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--white);
    max-width: 450px;
    width: 100%;
    letter-spacing: 0.5px;
    transition: all 0.3s ease-in-out;
}

.common-submit-btn.csb-width-fit{
    max-width: fit-content;
    width: 100%;
    padding: 9px 25px;
}

.common-submit-btn.csb-width-full,
.lsb-loader-btn.csb-width-full{
    max-width: 100%;
}

.common-submit-btn:not([disabled]):hover{
    color: var(--white);
    background-color: var(--adiant-orchid);
}


.common-submit-btn[disabled]{
    opacity: 0.5;
}
/* --- login & submit btn end --- */


/* --- lsb loader btn start --- */
.lsb-loader-btn{
    color: var(--white);
    background-color: var(--adiant-orchid);
}

.lsb-loader-btn img{
    pointer-events: none;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    position: relative;
    margin-right: 8px;
    animation: rotation-btn 1s infinite linear;
    transition: all 0.3s ease-in-out;
}

@-webkit-keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes rotation-btn {
    from {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes rotation-btn {
    from {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
/* --- lsb loader btn end --- */



/* --- ---- */
.signinup-group .sg-list-bx{
    border-radius: 50px;
    border: 1px solid var(--amethyst-haze);
    padding: 4px;
    display: flex;
    align-items: center;
    max-width: fit-content;
    width: 100%;
    gap: 8px;
    overflow: hidden;
    overflow-x: auto;
}

.signinup-group .sg-list-bx .sg-list-item{
    position: relative;
    z-index: 0;
    color: var(--white);
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 50px;
    letter-spacing: 0.5px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.signinup-group .sg-list-bx .sg-list-item::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: calc(100% + 10px);
    height: 100%;
    background: radial-gradient(45.74% 210.77% at 51.94% 130.65%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
    z-index: -1;
    transform: translateX(-50%);
    transition: all 0.3s ease-in;
    opacity: 0;
}

.signinup-group .sg-list-bx .sg-list-item img{
    background-color: var(--white);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-position: center;
    object-fit: contain;
}

.signinup-group .sg-list-bx .sg-list-item:hover{
    background-color: var(--white-05);
}

.signinup-group .sg-list-bx .sg-list-item.active{
    border-color: var(--adiant-orchid);
}

.signinup-group .sg-list-bx .sg-list-item.active::after{
    opacity: 1;
}

/* -=-=-=-=-=-=-=-=- */

.signinup-group .sg-list-bx.slb-style-2{
    border-radius: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    flex-wrap: wrap;
    gap: 8px;
    overflow: hidden;
    overflow-x: auto;
}

.signinup-group .sg-list-bx.slb-style-2 .sg-list-item{
    border: 1px solid var(--white-20);
    line-height: 1.4;
}

/* -=-=-=-=-=-=-=-=- */

.signinup-group .sg-list-bx.slb-img .sg-list-item{
    padding: 2px;
}

/* --- --- */


/* --- signinup-group-profile Start ---  */
.signinup-group-profile{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 18px;
}

.signinup-group-profile input{
    display: none;
}

.signinup-group-profile .sgp-lable{
    position: relative;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border: 1px solid var(--white-20);
    background-color: var(--white-05);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.signinup-group-profile .sgp-lable.dragover,
.signinup-group-profile .sgp-lable:hover{
    border-color: var(--adiant-orchid);
}

.signinup-group-profile .sgp-lable img{
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.signinup-group-profile .sgp-lable .sgp-overlay{
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    font-weight: 500;
    letter-spacing: 0.5px;
    width: 100%;
    height: 100%;
    color: var(--white);
    background-color: var(--obsidian-mist-50);
    backdrop-filter: blur(1px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.signinup-group-profile .sgp-lable .sgp-overlay svg{
    width: 30px;
    height: 30px;
}

.signinup-group-profile .sgp-lable.dragover .sgp-overlay,
.signinup-group-profile .sgp-lable:hover .sgp-overlay{
    opacity: 1;
    visibility: visible;
}

.signinup-group-profile .sgp-btn{
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    font-size: 12px;
    width: fit-content;
    font-weight: 500;
    white-space: nowrap;
    border: 1px solid var(--white-10);
    background-color: var(--white-05);
    transition: all 0.3s ease-in-out;
}

.signinup-group-profile .sgp-btn svg{
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.signinup-group-profile .sgp-btn.sgp-edit:hover{
    color: var(--white);
    border-color: var(--adiant-orchid);
    background-color:var(--adiant-orchid-30);
}

.signinup-group-profile .sgp-btn.sgp-delete:hover{
    color: var(--white);
    border-color: var(--coral-red);
    background-color: var(--coral-red-30);
}


@media screen and (max-width:380px) {
    .signinup-group-profile{
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:768px) {
    .signinup-group-profile .sgp-lable .sgp-overlay{
        display: none;
    }
}

/* --- signinup-group-profile End ---  */


/* --- Common file upload start --- */
.fileupload{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 10px;
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
    background-color:  var(--white-05);
    border: 1px dashed var(--white-20);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.fileupload .feud-img{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.fileupload svg{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-lable{
    color: var(--white-70);
    font-weight: 400;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-sub-lable{
    font-weight: 400;
    color: var(--white);
    font-size: 12px;
}

.fileupload .feud-lable span{
    font-weight: 500;
    color: var(--adiant-orchid);
    text-decoration: underline;
}

.fileupload.dragover,
.fileupload:hover{
    border-style: solid;
    border-color: var(--adiant-orchid);
    box-shadow: 0 0 0px 0.1px var(--adiant-orchid);
}

.fileupload.dragover *{
    pointer-events: none
}

.fileupload.dragover .feud-img,
.fileupload.dragover svg,
.fileupload:hover svg,
.fileupload:hover .feud-img{
    opacity: 1;
}

.fileupload:hover .feud-lable{
    color: var(--white);
}
/* --- Common file upload end --- */


/* --- Common file upload item start --- */
.upload-document-item{
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--white-20);
    background-color:  var(--white-05);
}

.upload-document-item .udi-img-bx{
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    background-color:  var(--white-05);
    border-radius: 8px;
    overflow: hidden;
}

.upload-document-item .udi-img-bx img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.upload-document-item .udi-data-bx{
    flex: auto;
}

.upload-document-item .udi-data-bx .udi-data-name{
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}

.upload-document-item .udi-data-bx .udi-data-type-size{
    font-weight: 500;
    color: var(--white-70);
    font-size: 12px;
    margin-top: 3px;
}

.upload-document-item .udi-icon-bx{
    cursor: pointer;
    position: relative;
    color: var(--white-70);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    flex: 0 0 46px;
    padding: 10px;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
}

.upload-document-item .udi-icon-bx::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color:var(--red2-color);
    transform: scale(0);
    transform-origin: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
}

.upload-document-item .udi-icon-bx:active,
.upload-document-item .udi-icon-bx:hover{
    color: var(--white);
}

.upload-document-item .udi-icon-bx:hover::after{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.upload-document-item .udi-icon-bx svg{
    position: relative;
    z-index: 1;
}

.upload-document-item .udi-icon-bx.udi-delete:active,
.upload-document-item .udi-icon-bx.udi-delete:hover{
    color: var(--red-color);
}
/* --- Common file upload item end --- */



/* --- Signinup group switche start ---  */
.signinup-group-switche{
    position: relative;
    background-color: var(--oxford-blue);
    border-radius: 50px;
    height: 20px;
    width: 34px;
    flex: 0 0 34px;
    box-shadow: 0 0 0 1px var(--oxford-blue);
    transition: all 0.3s linear !important;
    cursor: pointer;
}

.signinup-group-switche .sgs-inner{
    position: absolute;
    left: calc(0px + 2px);
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    padding: 2px;
    border-radius: 50%;
    color: var(--oxford-blue);
    background-color: var(--white);
    transition: all 0.1s linear !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signinup-group-switche .sgs-inner svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

@media screen and (min-width:768px) {   
    .signinup-group-switche{
        opacity: 0.9;
    }

    .signinup-group-switche:hover{
        opacity: 1;
    }
}

.signinup-group-switche.active{
    color: var(--adiant-orchid);
    box-shadow: 0 0 0 1px var(--adiant-orchid);
    background-color: var(--adiant-orchid);
}

.signinup-group-switche.active .sgs-inner{
    color: var(--adiant-orchid);
    background-color: var(--white);
    left: calc(100% - 0px - 18px);
}
/* --- Signinup group switche end ---  */
