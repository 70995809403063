* {
    text-decoration: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    scroll-behavior: initial !important;
}

.no-transition {
    transition: none !important;
}

a, a:hover {
    text-decoration: none;
    color: var(--white);
}

/* --- Website Fonts --- */
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-display: swap;
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'public sans';
    src: url('../fonts/Public_Sans/PublicSans-VariableFont_wght.ttf') format('truetype');
    font-display: swap;
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-VariableFont_wght.ttf') format('truetype');
    font-display: swap;
    font-weight: 200 800;
    font-style: normal;
}

@font-face {
    font-family: 'Orbitron';
    src: url('../fonts/Orbitron/Orbitron-VariableFont_wght.ttf') format('truetype');
    font-display: swap;
    font-weight: 400 900;
    font-style: normal;
}


/* --- Body tag start --- */
body {
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--white);
    background-color: var(--deep-eclipse);
}

.overflowhidden,
body.overflowhidden {
    overflow: hidden;
}

.common-word-wrap {
    word-break: break-word;
}

.scrollbarHidden::-webkit-scrollbar {
    display: none;
}

.common-pe-none{
    pointer-events: none;
}
/* --- Body tag end --- */


/* --- Skeletant Css Start --- */
.skeletant-bx {
    pointer-events: none;
}

.skeletant-design {
    background-color: var(--white-05);
    background-image: linear-gradient(45deg, transparent 30%, var(--white-05) 50%, transparent 70%);
    background-position: left;
    background-repeat: repeat;
    background-size: 1200px;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: shine-lines 1s infinite linear;
    pointer-events: none;
    border-radius: 12px;
}

.sh-49{
    height: 45px;
}

@-webkit-keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}

.table-skeletant-bx .skeletant-design{
    margin: 2px 0;
    border: 1px solid var(--charcoal-grey);
}

.table-skeletant-bx .skeletant-design:last-child {
    margin-bottom: 0;
}
/* --- Skeletant Css End --- */


/* --- Common logo bx start --- */
.logo-bx{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.logo-bx img.logo-img{
    width: 240px;
    height: 29px;
    object-position: center;
    object-fit: contain;
}

.logo-bx img.logo-icon{
    width: 29px;
    height: 29px;
    object-fit: contain;
    object-position: center;
    display: block;
}

.logo-bx img.logo-text{
    width: auto;
    height: 26px;
}

.logo-bx > img{
    transition: all 0.3s ease-in-out;
}
/* --- common logo bx end --- */


/* --- User info bx start --- */
.user-info-bx{
    width: 100%;
    padding: 5px 10px 5px 5px;
    border-radius: 50px;
    box-shadow: 0 0 0 1px var(--white-10);
    background: linear-gradient(90deg, var(--eclipse-gray) 0%,  transparent 100%);
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease-in-out;
}

.user-info-bx:hover{
    background-color: var(--velvet-twilight);
}

.user-info-bx .uib-img{
    width: 34px;
    height: 34px;
    flex: 0 0 34px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 1px var(--charcoal-grey);
}

.user-info-bx .uib-img img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.user-info-bx .uib-data .uib-name{
    font-size: 12px;
    font-weight: 500;
    color: var(--white);
}

.user-info-bx .uib-data .uib-other{
    font-size: 10px;
    font-weight: 400;
    color: var(--slate-grey);
}
/* --- User info bx end --- */



/* --- Common color status bx start --- */
.status-yellow {
    color: var(--yellow-color) !important;
}
.status-yellow2 {
    background-color: var(--yellow2-color) !important;
    color: var(--yellow-color) !important;
}
/* --- */

.status-green {
    color: var(--green-color) !important;
}

.status-green2 {
    background-color: var(--green2-color) !important;
    color: var(--green-color) !important;
}

.status-green3 {
    background-color: var(--green4-color) !important;
    color: var(--green3-color) !important;
}

.status-green4 {
    background-color: var(--green-color-10) !important;
    color: var(--green-color) !important;
}
/* --- */

.status-red {
    color: var(--red-color) !important;
}

.status-red2 {
    background-color: var(--red2-color) !important;
    color: var(--red-color) !important;
}

.status-red3 {
    background-color: var(--red-color-10) !important;
    color: var(--red-color) !important;
}

/* --- */

.status-obsidian{
    color: var(--obsidian-mist);
}

.status-obsidian2{
    color: var(--obsidian-mist);
    background-color: var(--obsidian-mist-10);
}


/* --- */

.status-white {
    color: var(--white) !important;
}

.status-white2 {
    color: var(--white) !important;
    background-color: var(--white-10);
}


/* --- */


.status-neon-green {
    color: var(--neon-green) !important;
}
/* --- Common color status bx end --- */



/* --- Custome wrapper start --- */
.custome-wrapper {
    padding: 15px;
    padding-top: 15px;
    padding-bottom: 50px;
    transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 1200px) {
    .custome-wrapper {
        padding-left: calc(240px + 30px);
    }
    
    .custome-wrapper.cw-smb-mini {
        padding-left: calc(75px + 30px);
    }
}
/* --- Custome wrapper end --- */



/* --- Bootsrep container start --- */
.container-lg.cl-custome {
    max-width: 1140px;
}

.container-lg.cl-custome2 {
    max-width: 1200px;
}

.container-lg.cl-custome3 {
    max-width: 1920px;
    padding: 0;
}
/* --- Bootsrep container end --- */



/* --- Screen loader start --- */
.screen-loader-bx {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--deep-eclipse);
    z-index: 3;
    transition: all 0.3s ease-in-out;
}

.screen-loader-bx .slb-inner {
    width: 50px;
    height: 50px;
    border: 3px solid var(--white-20);
    border-radius: 50%;
    border-top-color: var(--adiant-orchid);
    animation: slb-rotate 2s linear infinite;
}
  
@keyframes slb-rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (min-width: 1200px) {   
    .custome-wrapper .screen-loader-bx {
        padding-top: 70px;
        padding-left: 240px;
    }

    .custome-wrapper.cw-smb-mini .screen-loader-bx {
        padding-left: 75px;
    }
}
/* --- Screen loader end --- */



/* --- Alert Message start --- */
.alert-text-bx{
    position: relative;
    padding: 15px;
    border-radius: 12px;
    background-color: var(--midnight-carbon);
}

.alert-text-bx .atb-heading{
    display: flex;
    font-size: 16px;
    font-weight: 600;
    color: var(--white);
    gap: 6px;
}

.alert-text-bx .atb-heading span{
    font-weight: 500;
}

.alert-text-bx .atb-heading svg{
    height: 24px;
    width: 24px;
    position: relative;
    top: -1.5px;
}

.alert-text-bx .atb-text{
    font-size: 14px;
    font-weight: 400;
    color: var(--white-70);
    margin-top: 6px;
}

.alert-wbtn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.alert-fd-column{
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
    gap: 15px;
}


.alert-text-bx .atb-btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: fit-content;
    cursor: pointer;
    padding: 8px 16px;
    height: 39px;
    white-space: nowrap;
    border-radius: 50px;
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    border:1px solid var(--white);
    transition: all 0.3s ease-in-out;
}

.alert-text-bx .atb-btn svg{
    width: 18px;
    height: 18px;
    stroke-width: 2px;
}

.alert-text-bx .atb-btn:hover{
    color: var(--deep-eclipse);
    background-color: var(--white);
    border-color: var(--white);
}

.alert-text-bx .atb-btn.atb-gradient-btn{
    overflow: hidden;
    color: var(--white);
    border-color: var(--velvet-twilight);
    background-color: transparent;
}

.alert-text-bx .atb-btn.atb-gradient-btn::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(45.74% 210.77% at 51.94% 130.65%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
    z-index: -1;
    transform: translateX(-50%);
    transition: all 0.3s ease-in;
}

.alert-text-bx .atb-btn.atb-gradient-btn:hover::after{
    width: calc(100% + 60px);
}

@media screen and (max-width:576px) {
    .alert-wbtn {
        align-items: inherit;
        flex-direction: column;
        justify-content: inherit;
    }

    .alert-text-bx .atb-btn{
        width: 100%;
    }
}

/* --- */
.alert-style2 .atb-heading{
    align-items: center;
}

.alert-style2 .atb-inner .atb-icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    flex:0 0 36px;
    border-radius: 5px;
    z-index: 2;
    overflow: hidden;
}

.alert-style2 .atb-inner .atb-icon::after{
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    opacity: 0.2;
    z-index: 1;
}
/* --- */


/* --- */
.alert-style3 .atb-heading{
    flex-direction: column;
    margin-bottom: -2px;
}

.alert-style3 .atb-heading svg{
    width: 40px;
    height: 40px;
    margin-bottom: 6px;
}
/* --- */

/* --- */
.alert-style4.alert-text-bx{
    background-color: inherit;
}

.alert-style4 .atb-heading{
    color: currentColor;
}

.alert-style4 .atb-text{
    color: currentColor;
}
/* --- */

/* --- Alert Message end --- */



/* --- Breadcrumb Start --- */
.breadcrumb-main-bx {
    margin-bottom: 15px;
}

.breadcrumb-heading-bx {
    font-size: 24px;
    color: var(--white);
    font-weight: 500;
    white-space: nowrap;
}

.breadcrumb-heading-bx svg {
    width: 24px;
    height: 24px;
    position: relative;
    top: -2px;
    margin-right: 8px;
}

.breadcrumb-bx {
    display: none;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    margin-top: 4px;
}

.breadcrumb-bx .breadcrumb-sapretor svg {
    width: 16px;
    height: 16px;
    position: relative;
    top: -1px;
    stroke-width: 2px;
    color: var(--slate-grey);
    margin: 0 6px;
}

.breadcrumb-bx .breadcrumb-link {
    font-size: 14px;
    font-weight: 500;
    color: var(--slate-grey);
    transition: all 0.3s ease-in;
}

.breadcrumb-bx .breadcrumb-link svg{
    width: 18px;
    height: 18px;
    position: relative;
    top: -1px;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active {
    font-weight: 500;
    color: var(--white);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link:hover {
    color: var(--white);
    cursor: pointer;
}
/* --- Breadcrumb End --- */



/* --- Common Box Start --- */
.common-box {
    position: relative;
    padding: 15px;
    border-radius: 16px;
    box-shadow: 0 0 0 1px var(--white-10);
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid-15) 0%, var(--obsidian-mist-15) 100%);
}

.common-box.cb-color2 {
    background: none;
}

.common-box.cb-color3 {
    background: radial-gradient(46.05% 211.96% at 50% 59.68%, var(--adiant-orchid-30) 0%, var(--adiant-orchid-09) 100%)
}

.common-box.cb-all-none {
    box-shadow: none;
    border: none;
    background: none;
    padding: 0;
}

.common-box-heading {
    color: var(--white);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
}

.common-box-heading.cbh-16small {
    font-size: 16px;
}

.common-box-heading.cbh-20big {
    font-size: 20px;
}

.common-box-heading img {
    width: 36px;
    height: 36px;
    margin-right: 4px;
    position: relative;
    top: -2px;
}

.common-box-heading svg {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    position: relative;
    top: -2px;
}

.common-box-heading.cbh-16small svg {
    width: 20px;
    height: 20px;
    top: -2.3px;
}


.common-box-text {
    color: var(--white-80);
    font-size: 14px;
    font-weight: 400;
}

.common-section-heading {
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 15px;
}

.common-section-separator {
    margin: 15px 0;
    width: 100%;
    height: 1px;
    background: var(--white-20);
}

.common-section-separator.cs-style2{
    background: radial-gradient(46.05% 211.96% at 50% 59.68%, var(--adiant-orchid) 0%, transparent 100%);
}

.common-sticky {
    position: sticky;
    top: 85px;
}

.common-box.cb-100-percent{
    height: 100%;
}

@media screen and (max-width:991px) {
    .common-box-heading {
        font-size: 16px;
    }

    .common-box-heading img{
        width: 30px;
        height: 30px;
    }
}
/* --- Common Box End --- */


/* --- Common btn start --- */
.cbi-gap-10px {
    gap: 10px;
}

.common-btn{
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 14px;
    width: fit-content;
    font-weight: 500;
    white-space: nowrap;
    background: transparent;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    letter-spacing: 1px;
}

.common-btn svg {
    position: relative;
    top: -1px;
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.common-btn.cb-small {
    font-size: 12px;
    height: 32px;
    min-width: auto;
    padding: 0 10px;
}  

/* --- --- */
.cb-outline::before{
    content: " ";
    position: absolute;
    inset: 1px;
    border-radius: 50px;
    background-color: var(--deep-eclipse);
    z-index: -1;
    transition: background-color 0.3s ease-in;
}

.cb-outline::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid) 0%, var(--adiant-orchid-15) 100%);
    z-index: -2;
    /* opacity: 0; */
    transition: opacity 0.3s ease-in-out;
}

.cb-outline:hover::before{
    background-color: var(--adiant-orchid);
}

/* --- --- */
.cb-fill{
    border-color: var(--amethyst-haze);
}

.cb-fill::before{
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(45.74% 210.77% at 51.94% 130.65%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
    z-index: -1;
    transform: translateX(-50%);
    transition: all 0.3s ease-in;
}

.cb-fill:hover::before{
    width: calc(100% + 60px);
}

/* --- --- */
.cb-white-fill{
    border-color: var(--white-10);
    background-color: var(--white-05);
}

.cb-white-fill:hover{
    color: var(--deep-eclipse);
    background-color: var(--white);
}

/* @media screen and (max-width:992px) {
    .common-btn {
        padding: 0 10px;
    }  
}

@media screen and (max-width:576px) {
    .common-btn.cb-small {
        padding: 0 10px;
    }  
} */
/* --- Common btn end --- */


/* --- Common box info btn start --- */
.common-box-info {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -2px;
    border-radius: 50px;
    color: var(--white-70);
    transition: all 0.3s ease-in-out;
}

.common-box-info:hover {
    color: var(--adiant-orchid);
}

.common-box-info svg {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
}
/* --- Common box info btn end --- */



/* --- Calendar navigation start --- */
.calendar-navigation-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.calendar-navigation-bx .cnb-btn{
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    border-radius: 50px;
    color: var(--white);
    background-color: var(--white-05);
    box-shadow: 0 0 0 1px var(--white-10);
    padding: 8px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.calendar-navigation-bx .cnb-btn::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    background-color: var(--adiant-orchid);
    background-image: radial-gradient(66.38% 298.87% at 50% -57.2%,  var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
}

.calendar-navigation-bx .cnb-btn svg{
    width: 12px;
    height: 12px;
    object-fit: contain;
    object-position: center;
}

.calendar-navigation-bx .cnb-date{
    font-size: 14px;
    font-weight: 500;
    min-width: 100px;
    text-align: center;
    white-space: nowrap;
}

@media screen and (min-width:768px) { 
    .calendar-navigation-bx .cnb-btn:hover{
        background-color: transparent;
    }

    .calendar-navigation-bx .cnb-btn:hover::after{
        opacity: 1;
    }
}

@media screen and (max-width:767px) and (min-width:0px) { 
    .calendar-navigation-bx .cnb-btn:active{
        background-color: transparent;
    }

    .calendar-navigation-bx .cnb-btn:active::after{
        opacity: 1;
    }
}
/* --- Calendar navigation end --- */



/* --- Bootsrep modal start --- */
.modal {
    backdrop-filter: blur(5px);
}

.modal.fade.zoom .modal-dialog {
    transform: scale(0.9);
}

.modal.zoom.show .modal-dialog {
    transform: none;
}

.custom-content .modal-content {
    border: 1px solid var(--white-10);
    background: radial-gradient(44.01% 202.5% at 50% 138.92%, var(--royal-plum) 0%, var(--midnight-violet) 100%);
    border-radius: 16px;
}

.custom-content.cc-mw396px .modal-content {
    max-width: 396px;
    margin: auto;
}

.custom-modal-header {
    position: sticky;
    padding: 20px 15px 20px;
}

.custom-modal-header .cmh-lable {
    font-size: 20px;
    font-weight: 500;
    color: var(--white);
    text-align: center;
}

.custom-modal-header .cmh-sub-lable {
    color: var(--white-70);
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
}

.custom-modal-body {
    padding: 0 15px;
}
/* --- Bootsrep modal end --- */



/* --- Close btn start --- */
.close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    border-radius: 100%;
    display: flex;
    color: var(--white);
    padding: 4px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.close-icon.ci-tr-0px {
    top: -4px;
    right: 0;
}

.close-icon:hover {
    transform: rotate(360deg);
}

@media screen and (min-width:700px) {
    .close-icon:hover {
        background-color: var(--white-05);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    .close-icon:active {
        background-color: var(--white-05);
    }
}
/* --- Close btn end --- */


/* --- Common back btn start --- */
.common-back-btn {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 8px;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.common-back-btn:hover {
    opacity: 1;
}

.common-back-btn svg {
    width: 24px;
    stroke-width: 2px;
    height: 24px;
    position: relative;
    top: 1px;
}

.common-back-btn.cbb-absolute {
    position: absolute;
    left: 0px;
    top: 1px;
}

.cbb-80pl {
    padding-left: 80px;
}

/* --- Common back btn End --- */



/* --- Common data bx start --- */
.common-data-bx {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--white-10);
    background-color: var(--white-05);
}

.common-data-bx .cdb-item {
    display: flex;
    justify-content: space-between;
    color: var(--white);
}

.common-data-bx .cdb-item .cdb-label {
    color: var(--white-70);
    font-size: 14px;
    font-weight: 500;
}

.common-data-bx .cdb-item .cdb-data {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    width: fit-content;
}
/* --- Common data bx end --- */



/* --- Common tabs bx Start --- */
.common-tabs-bx {
    display: flex;
    gap: 8px;
}

.common-tabs-bx .ctb-item {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--lavender-mist);
    border: 1px solid transparent;
    border-radius: 50px;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 15px;
    height: 44px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    line-height: 0;
}

.common-tabs-bx .ctb-item svg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
}

.common-tabs-bx .ctb-item::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid) 0%, var(--adiant-orchid-15) 100%);
    z-index: -2;
    opacity: 0;
    /* transition: opacity 0.3s ease-in-out; */
}

.common-tabs-bx .ctb-item::before {
    content: " ";
    position: absolute;
    inset: 1px;
    border-radius: 50px;
    background-color: var(--deep-eclipse);
    z-index: -1;
    opacity: 0;
    /* transition: opacity 0.3s ease-in-out; */
}

.common-tabs-bx .ctb-item:not(.active):hover{
    background-color: var(--white-05);
}

.common-tabs-bx .ctb-item.active::before,
.common-tabs-bx .ctb-item.active::after {
    opacity: 1;
}

@media screen and (max-width:576px) {
    .common-tabs-bx .ctb-item {
        height: 40px;
    }
    .common-tabs-bx .ctb-item svg{
        width: 18px;
        height: 18px;
        flex: 0 0 18px;
    }
}

/* --- Common tabs bx end --- */



/* --- Common drop bx start --- */
.common-drop-item{
    position: relative;
    margin-bottom: 10px;
    user-select: none;
}

.common-drop-item .common-drop-bx{
    position: absolute;
    top: calc(100% + 0px);
    right: 0;
    visibility: hidden;
    width: 100%;
    z-index: 2;
}

.common-drop-item .common-drop-bx .common-drop-inner{
    margin-top: 1px;
    border-radius: 8px;
    max-height: 215px;
    background-clip: padding-box;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border:1px solid var(--white-10-full);
    background-color: var(--obsidian-mist);
    opacity: 0;
    visibility: hidden;
    transform: translateY(1px);
    overflow: hidden;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-track {
    background-color: var(--obsidian-mist);
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--white-10);
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb:hover {
    background-color: var(--white-70);
}

.common-drop-item.active .common-drop-bx{
    visibility: visible;
}

.common-drop-item.active .common-drop-bx .common-drop-inner{
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

/* @media screen and (min-width:992px) {
    body.isDropActive{
        overflow: hidden;
        padding-right: 17px;
    }
} */
/* --- Common drop bx end--- */


/* --- Common drop data start --- */
.cdi-current{
    cursor: pointer;
    position: relative;
    color: var(--white);
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    padding-left: 15px;
    padding-right: 36px;
    height: 38px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    border: 1px solid var(--white-10);
    transition: border 0.3s ease-in-out;
}

.cdi-current::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid) 0%, var(--adiant-orchid-15) 100%);
    z-index: -2;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.cdi-current::before{
    content: " ";
    position: absolute;
    inset: 1px;
    border-radius: 50px;
    background-color: var(--deep-eclipse);
    z-index: -1;
    transition: background-color 0.3s ease-in;
}

.cdi-current .cc-icon{
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    padding: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cdi-current .extra-icon-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    position: relative;
    top: 0px;
    margin-right: 5px;
}

.cdi-current .extra-icon-bx svg,
.cdi-current .cc-icon svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease-in-out;
}

.cdi-item{
    color: var(--lavender-mist);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    padding: 8px 15px;
    position: relative;
    white-space: nowrap;
    transition: all .3s ease-in-out;
}

.ci-tf-capitalize .cdi-current,
.ci-tf-capitalize .cdi-item{
    text-transform: capitalize;
}

.cdi-item:hover{
    color: var(--white);
    background-color: var(--white-05);
}

.cdi-item.cdi-seleted{
    color: var(--white);
    background-color: var(--adiant-orchid-50);
}


.common-drop-item.active .cdi-current,
.cdi-current:hover{
    border-color: transparent;
}

.common-drop-item.active .cdi-current::after,
.cdi-current:hover::after{
    opacity: 1;
}

.common-drop-item.active .cdi-current .cc-icon svg{
    transform: rotate(-180deg);
}
/* --- Common drop data end --- */



/* --- Social icons end --- */
.social-icon-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.social-icon-bx .sib-item{
    position: relative;
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
    padding: 10px;
    border-radius: 50%;
}

.social-icon-bx .sib-item svg,
.social-icon-bx .sib-item img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.social-icon-bx .sib-item svg{
    position: absolute;
    left: 0;
    top: 0;
}
/* --- Social icons start --- */




/* --- Chart design css start--- */
.Mycharts {
    width: calc(100% + 34px);
    margin-top: -20px;
    margin-bottom: -10px;
    position: relative;
    /* z-index: -1; */
}

.Mycharts .MuiChartsLegend-series text {
    font-size: 12px !important;
}

.Mycharts .MuiChartsLegend-series .MuiChartsLegend-mark {
    width: 15px !important;
    height: 15px !important;
    transform: translate(5px, 3px);
}

.Mycharts .MuiChartsLegend-series text,
.Mycharts .MuiChartsAxis-label {
    font-family: 'Inter' !important;
    font-weight: 600 !important;
    fill: var(--black) !important;
}

.Mycharts .MuiChartsGrid-verticalLine,
.Mycharts .MuiChartsAxis-line,
.Mycharts .MuiChartsAxis-tick {
    stroke-width: 0 !important;
}

.Mycharts .MuiChartsAxisHighlight-root,
.Mycharts .MuiChartsGrid-horizontalLine{
    stroke-dasharray: 2px !important;
    stroke: var(--white-20) !important;
}

.Mycharts .MuiChartsAxis-tickLabel {
    fill: var(--slate-grey) !important;
    font-family: 'public sans' !important;
    font-weight: 400 !important;
}

.Mycharts .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel {
    transform: translateY(6px) !important;
}

.Mycharts .css-1f57y8b {
    fill: var(--slate-grey);
    color: var(--slate-grey);
}

@media screen and (max-width:576px) {
    .Mycharts {
        pointer-events: none;
    }
}


/* --- --- */
.Mycharts.account-chart{
    width: calc(100% + 50px);
    margin-bottom: 0px;
}

.Mycharts.account-chart .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel {
    transform: translateY(12px) !important;
}

@media screen and (min-width:1024px) {
    .Mycharts.account-chart.line-chart .MuiChartsAxis-directionX g:last-of-type .MuiChartsAxis-tickLabel {
        transform: translateY(12px) translateX(-40px) !important;
    }

    .Mycharts.account-chart.line-chart .MuiChartsAxis-directionX g:first-of-type .MuiChartsAxis-tickLabel {
        transform: translateY(12px) translateX(30px) !important;
    }
}


/* --- --- */
.Mycharts.volume-chart {
    width: calc(100% + 50px);
    margin-top: -40px;
    margin-bottom: -24px;
    margin-left: -15px;
}

.Mycharts.volume-chart .MuiChartsAxis-tickLabel{
    font-size: 10px !important;
    fill: var(--white) !important;
}
/* --- chart design end --- */



/* --- Common progress start --- */
.common-progress-bx{
    padding: 15px;
}

.common-progress-data{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.common-progress-data .cpd-label{
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
}

.common-progress-data .cpd-value {
    color: var(--white);
    font-size: 14px;
    font-weight: 400;
    text-align: right;
}

.common-progress-data .cpd-current-value {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

.common-progress-data .cpd-label span,
.common-progress-data .cpd-value span {
    font-size: 14px;
    color: var(--white-70);
}


.common-progress-bar {
    height: 10px;
    width: 100%;
    background-color: #2A1F2F;
    background-image: repeating-linear-gradient(33deg, transparent 0 4px, var(--white-10) 0 8px);
    overflow: hidden;
    border-radius: 50px;
}

.common-progress-bar.cpb-of-none {
    overflow: initial;

}

.common-progress-bar .common-progress-inner {
    position: relative;
    height: 100%;
    border-radius: 50px;
    background-color: var(--adiant-orchid);
    background-image: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
    box-shadow: 0 0 0 1px var(--adiant-orchid) inset;
    transition: all 0.3s ease-in-out;
}

.common-progress-bar .common-progress-inner .common-progress-circle {
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    padding: 6px;
    border: 2px solid var(--adiant-orchid);
    background-color: var(--white);
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px var(--black-30);
}
/* --- Common progress end --- */



/* --- Meter chart start --- */
.meter-chart-bx{
    position: relative;
    max-width: 260px;
    width: 100%;
    margin: 0 auto;
}

.meter-chart-bx img.mcb-meter-img{
    width: 100%;
}

.meter-chart-bx .mcb-meter-pointer{
    position: absolute;
    left: 50%;
    bottom: 17px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: right;
    align-items: center;
    transition: all 0.5s linear;
}

.meter-chart-bx .mcb-meter-pointer img{
    position: absolute;
    width: 120px;
}

.meter-chart-bx .mcb-data-bx{
    position: absolute;
    left: 50%;
    bottom: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 120px;
    transform: translateX(-50%);
}

.meter-chart-bx .mcb-data-bx .mcb-label{
    font-size: 14px;
    font-weight: 500;
    color: var(--white-70);
}

.meter-chart-bx .mcb-data-bx .mcb-value{
    font-size: 24px;
    color: var(--white);
    display: none;
}
/* --- Meter chart end --- */


/* --- Arc chat start --- */
.arc-item .arc-gauge-bx{
    position: relative;
    height: 225px;
}

.arc-item .arc-gauge-bx .MuiGauge-root{
    position: absolute;
    left: 0;
    top: 0;
}

.arc-item .arc-gauge-bx .agb-center{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.arc-item .arc-value{
    font-family: 'public sans';
    font-size: 80%;
    text-align: center;
    font-weight: 600;
}

.arc-item .arc-other-value{
    font-family: 'public sans';
    font-size: 9px;
    font-weight: 400;
    text-align: center;
}
/* --- Arc chat end --- */



/* --- Custome table Start --- */
.custom-table-responsive {
    border-radius: 6px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table thead {
    background-color: var(--white-05);
}

.custom-table thead tr th {
    font-weight: 600;
    color: var(--white);
    font-size: 12px;
    white-space: nowrap;
    padding: 10px 15px;
    cursor: pointer;
    font-family: 'Manrope';
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Table thead (Sorting icons) start */
.custom-table thead tr th .th-sort-icons {
    position: relative;
    top: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--gunmetal-gray);
    width: 14px;
    height: 14px;
    margin-left: 4px;
    transform: rotateX(0deg);
    transition: all 0.3s ease-in-out;
}

.custom-table thead tr th:hover .th-sort-icons,
.custom-table thead tr th.active .th-sort-icons {
    color: var(--white);
}

.custom-table thead tr th .th-sort-icons svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.custom-table thead tr th.desc .th-sort-icons {
    transform: rotateX(180deg);
}

.custom-table thead tr th.asc .th-sort-icons svg,
.custom-table thead tr th.desc .th-sort-icons svg {
    color: var(--adiant-orchid);
}
/* Table thead (Sorting icons) end */

.custom-table:not(.off-tr-bg) tbody tr {
    border-top: 1px solid var(--charcoal-grey);
    transition: all 0.3s ease-in-out;
    background-color: transparent;
}

/* .custom-table:not(.off-tr-bg) tbody tr:nth-child(odd) {
    background-color: transparent;
}

.custom-table:not(.off-tr-bg) tbody tr:nth-child(even) {
    background-color: transparent;
} */

.custom-table:not(.off-tr-bg) tbody tr:hover {
    background-color: var(--white-05);
}

.custom-table tbody tr td {
    position: relative;
    padding: 12px 15px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--white-70);
    white-space: nowrap;
    line-height: normal;
}

.custom-table tbody tr td.td-wrap {
    text-wrap: wrap;
}

.custom-table tbody tr td > svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.custom-table tbody tr td .td-user{
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-table tbody tr td .td-user .tdu-img-bx{
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    background: linear-gradient(180deg, var(--neon-fuchsia) 0%, transparent 55%, var(--neon-fuchsia) 100%);
    clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
}

.custom-table tbody tr td .td-user .user-img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    display: block;
    transform: scale(0.95);
    clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
}

.custom-table tbody tr td .td-user .user-name{
    font-size: 14px;
    color: var(--white);
    font-weight: 500;
}

.custom-table tbody tr td .td-user .user-country{
    margin-top: 2px;
    font-size: 12px;
    display: block;
}

.custom-table tbody tr td .td-img{
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-table tbody tr td .td-img img{
    width: 24px;
    height: 24px;
}

.custom-table tbody tr td .td-status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 4px 8px;
    border-radius: 50px;
    line-height: 20px;
    width: 47px;
    height: 22px;
}

.custom-table tbody tr td .td-status.ts-step,
.custom-table tbody tr td .td-status.ts-payment {
    width: fit-content;
    border-radius: 50px;
    text-transform: capitalize;
    font-size: 12px;
    /* border: 1px solid currentColor; */
}

.custom-table tbody tr td .td-status.td-dot {
    position: relative;
    padding-left: 20px;
}

.custom-table tbody tr td .td-status.td-dot::after {
    content: " ";
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-color: currentColor;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.custom-table tbody tr td .td-admin-note {
    max-width: 200px;
}
/* --- Custome table end --- */



/* --- Pagenation start --- */
.pagination-filter-bx{
    position: relative;
    padding: 15px 0px 0px;
}

.pagination-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-bx .pb-next,
.pagination-bx .pb-prev{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 50%;
    background-color: var(--white-20);
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.6px;
    transition: all 0.3s ease-in-out;
}

.pb-number-bx .pnb-item{
    min-width: 40px;
    height: 40px;
}

.pagination-bx .pb-next svg,
.pagination-bx .pb-prev svg{
    width: 16px;
    height: 16px;
    object-fit: contain;
    object-position: center;
    stroke-width: 2.4px;
}

.pagination-bx .pb-next:not(.pb-disabled):hover,
.pagination-bx .pb-prev:not(.pb-disabled):hover{
    color: var(--white);
    background-color: var(--velvet-twilight);
}

.pagination-bx .pb-next.pb-disabled,
.pagination-bx .pb-prev.pb-disabled{
    cursor: not-allowed;
    opacity: 0.7;
}


.pb-number-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
}

.pb-number-bx .pnb-item{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 36px;
    height: 36px;
    line-height: 0;
    padding: 4px;
    border-radius: 50px;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    color: var(--lavender-mist);
    letter-spacing: 0.6px;
    transition: all 0.3s ease-in-out;
}

.pb-number-bx .pnb-item svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.pb-number-bx .pnb-item:not(.pnb-current, .pnb-disabled):hover{
    color: var(--white);
    background-color: var(--velvet-twilight);
}

.pb-number-bx .pnb-item.pnb-current{
    color: var(--deep-eclipse);
    background-color: var(--white);
}

.pb-number-bx .pnb-item.pnb-disabled{
    opacity: 0.5;
    margin: 0 -5px;
}

@media screen and (max-width:576px) {
    .pagination-filter-bx{
        padding-top: 65px;
    }
    .pb-number-bx{
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(-50%);
    }
}
/* --- Pagenation end --- */



/* --- Empty box css start ---*/
.empty-div-bx {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    min-height: 190px;
    padding: 30px 10px;
}

.empty-div-bx.edb-border-top{
    border-top: 1px solid var(--white-10);
}

.empty-div-bx .empty-img {
    width: 70px;
    display: block;
    margin-bottom: 5px;
}

.empty-div-bx .empty-heading {
    line-height: normal;
    color: var(--white);
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-sub-heading {
    font-weight: 300;
    color: var(--white);
    opacity: 0.5;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .common-btn {
    margin-top: 5px;
}

@media screen and (max-width:576px) {
    .empty-div-bx .empty-heading {
        font-size: 16px;
    }

    .empty-div-bx .empty-sub-heading {
        font-size: 12px;
    }
}
/*--- Empty box css end ---*/


/* --- Notice Start--- */
.legal-content {
    padding: 0 15px 25px;
}

.legal-content .legal-heading {
    font-size: 16px;
    color: var(--white);
    font-weight: 500;
    line-height: 1.5;
}

.legal-content ul li,
.legal-content p {
    font-size: 14px;
    line-height: 1.7;
    margin: 0;
    margin-top: 3px;
    color: var(--white-70);
    font-weight: 400;
    text-align: left;
}

.legal-content ul.font-16 li {
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 1.6;
}

.legal-content ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin: 0;
    margin-left: 10px;
}


.legal-content ul.ul-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

.legal-content ul.ul-dick {
    list-style: disc;
}

.legal-content ul.ul-alpha {
    list-style: lower-alpha;
}

.legal-content ul.ul-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.legal-content ul.ul-flex li {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-right: 30px;
    text-align: left;
}


@media screen and (max-width:992px) {
    .legal-content ul.ul-flex li {
        width: 33.33%;
    }
}

@media screen and (max-width:768px) {
    .legal-content ul.ul-flex li {
        width: 50%;
    }
}

@media screen and (max-width:450px) {
    .legal-content ul.ul-flex li {
        width: 100%;
        padding-right: 0px;
    }
}
/* --- Notice End --- */



/* --- Account status start --- */
.account-status-bx{
    position: relative;
    border-radius: 50px;
    padding: 3px 8px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 20px;
    background-color: var(--white);
}

.account-status-bx::after {
    content: " ";
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-color: currentColor;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
/* --- Account status end --- */



/* --- Cdi Account start --- */
.cdi-account-label{
    font-size: 14px;
    font-weight: 400;
    color: var(--white-70);
    margin-bottom: 8px;
}

.cdi-account{
    margin-bottom: 0;
}

.cdi-account .cdi-current{
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    width: 280px;
}

.cdi-account .account-status-bx{
    white-space: nowrap;
    max-width: 117px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cdi-account .cdi-item{
    display: flex;
    align-items: center;
    gap: 8px;
}
/* --- Cdi Account end --- */







/* --- Challenge Card Bx Start --- */
.challenge-card-bx {
    height: 100%;
    display: block;
    position: relative;
    border-radius: 12px;
    border: 1px solid var(--white-10);
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid-15) 0%, var(--obsidian-mist-15) 100%);
    min-height: 137px;
    transition: border 0.3s ease-in-out;
}

.challenge-card-bx .ccb-inner {
    position: relative;
    z-index: 1;
    padding: 10px;
    border-radius: 12px;
}

.ccd-ss-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.challenge-card-bx .ccb-step {
    display: block;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 8px;
    color: var(--white);
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
    border: 1px solid var(--adiant-orchid);
    text-transform: capitalize;
}


.challenge-card-bx .challenge-top-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-img img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-data .ccb-i-name {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-data .ccb-i-type {
    color: var(--white-60);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
}

.challenge-card-bx .challenge-trade-info {
    display: flex;
    margin-top: 15px;
    gap: 10px;
}

.challenge-card-bx .challenge-trade-info .cti-item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 12px;
    background-color: var(--white-10);
    width: 100%;
}

.challenge-card-bx .challenge-trade-info .cti-item .cti-icon {
    display: flex;
    width: 28px;
    height: 28px;
    color: var(--white);
}

.challenge-card-bx .challenge-trade-info .cti-item .cti-icon svg {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.challenge-card-bx .challenge-trade-info .cti-data-bx .cti-label {
    color: var(--white-70);
    font-size: 12px;
    font-weight: 500;
}

.challenge-card-bx .challenge-trade-info .cti-data-bx .cti-num {
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
    line-height: initial;
}

.challenge-card-bx .challenge-btns {
    display: none;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
}

.challenge-card-bx .challenge-btns .common-btn{
    width: 100%;
}

.challenge-card-bx.active .challenge-btns {
    display: flex;
}



.ccb-request-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 12px;
    padding: 15px;
    text-align: center;
    min-height: 137px;
    font-weight: 400;
    border: 1px solid var(--white-20);
    background-color: var(--white-05);
}

.ccb-request-info.ccb-real-ac {
    color: var(--adiant-orchid);
    border-color: var(--adiant-orchid);
}
/* --- Challenge card bx end --- */



/* --- Challenge credentials bx start --- */
.challenge-credentials-bx {
    display: flex;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--white-10);
    background-color: var(--white-05);
}

.challenge-credentials-bx .credential-item .credential-label {
    color: var(--white-70);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;
}

.challenge-credentials-bx .credential-item .credential-data {
    position: relative;
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    width: fit-content;
    padding-right: 35px;
}

.challenge-credentials-bx .credential-item .credential-data .credential-icon {
    cursor: pointer;
    position: absolute;
    display: flex;
    top: -1px;
    right: 4px;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    padding: 5px;
    color: var(--white-70);
    border: 1px solid var(--white-10);
    background-color: var(--white-05);
    transition: all .3s ease-in-out;
}

.challenge-credentials-bx .credential-item .credential-data .credential-icon svg {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
    stroke-width: 2px;
}

.challenge-credentials-bx .credential-item .credential-data .credential-icon:hover {
    background-color: var(--adiant-orchid);
    color: var(--white);
}
/* --- Challenge credentials bx end --- */



/* --- Account overview filter start --- */
.signinup-group.sg-aof-bx .sg-list-bx{
    gap: 4px;
}

.signinup-group.sg-aof-bx .sg-list-bx .sg-list-item{
    padding: 14px 12px;
    font-size: 12px;
    line-height: 0;
    text-align: center;
}

.dwmy-filter-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;
}


.signinup-group.sab-lb-filter{
    position: absolute;
    right: 15px;
    top: 15px;
}

@media screen and (max-width:576px) {

    .signinup-group.sab-lb-filter{
        position: static;
        top: inherit;
        right: inherit;
    }


    .dwmy-filter-bx{
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
    }

    .signinup-group.sg-aof-bx .sg-list-bx{
        max-width: 100%;
    }

    .signinup-group.sg-aof-bx .sg-list-bx .sg-list-item{
        width: 100%;
    }
}
/* --- Account overview filter end --- */



/* --- Account overview css start --- */
.aoi-outer{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
}

.account-overview-item{
    position: relative;
    padding: 0 15px;
}

.account-overview-item:first-child{
    padding-left: 0;
}

.account-overview-item:last-child{
    padding-right: 0;
}

.account-overview-item:not(:last-child):after{
    content: " ";
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    background-color: var(--white-10);
}

.account-overview-item .aoi-label {
    color: var(--white-70);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.account-overview-item .aoi-data {
    color: var(--white);
    font-size: 20px;
    font-weight: 400;
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.account-overview-item .aoi-data span{
    color: var(--white);
    font-size: 11px;
    font-weight: 400;
    border-radius: 50px;
    margin-left: 5px;
    line-height: 0;
    display: none;
}

.account-overview-item .aoi-data.aoi-status-red,
.account-overview-item .aoi-data span.aoi-status-red {
    color: var(--red-color);
}

.account-overview-item .aoi-data.aoi-status-green,
.account-overview-item .aoi-data span.aoi-status-green {
    color: var(--neon-green);
}


.account-overview-item .aoi-data.aoi-limit{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


/*  */
.aoi-style-2{
    row-gap: 10px;
    margin: 0 -5px;
}

.aoi-style-2 .aoi-inner .account-overview-item:after{
    content: none;
}

.aoi-style-2 .aoi-inner{
    width: 25%;
    flex: 0 1 25%;
    padding: 0 5px;
}

.aoi-style-2 .aoi-inner .account-overview-item{
    padding: 10px 12px;
    border-radius: 12px;
    border: 1px solid var(--white-10);
    background-color: var(--white-10);
}

@media screen and (max-width:768px) {
    .aoi-style-2 .aoi-inner{
        width: 50%;
        flex: 0 1 50%;
    }

    .account-overview-item .aoi-data {
        font-size: 14px;
    }
}



/*  */
.aoi-outer.aoi-fix-width .account-overview-item{
    width: 25%;
    flex: 0 1 25%;
}

@media screen and (max-width:768px) {
    .aoi-outer.aoi-fix-width .account-overview-item{
        width: 50%;
        flex: 0 1 50%;
    }

    .aoi-outer.aoi-fix-width .account-overview-item:nth-child(2)::after{
        content: none;
    }
    .aoi-outer.aoi-fix-width .account-overview-item:nth-child(3){
        padding-left: 0;
    }
}
/* --- Account overview css end --- */



/* --- Dashboard page start --- */

/* --- Dashboard (Welcome bx) start --- */
.welcome-bx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: calc(100vh - 153px);
}

.welcome-bx .wb-heading{
    color: var(--white);
    font-size: 42px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 6px;
}

.welcome-bx .wb-sub-heading{
    font-size: 14px;
    font-weight: 400;
    color: var(--white-70);
    margin-bottom: 20px;
}

.welcome-bx .cb-wb-btn{
    font-family: 'Orbitron';
    font-size: 26px;
    font-weight: 500;
    height: 70px;
    padding: 0 15px 0 20px;
    line-height: 0;
}

.welcome-bx .cb-wb-btn .cb-wb-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 42px;
    height: 42px;
    flex: 0 0 42px;
    padding: 12px;
    color: var(--deep-eclipse);
    background-color: var(--white);
    border-radius: 50%;
    margin-left: 10px;
    transition: all 0.3s ease-in-out;
}

.welcome-bx .cb-wb-btn .cb-wb-icon svg {
    top: 0;
    stroke-width: 3px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    margin: 0;
}

.welcome-bx .cb-wb-btn:hover .cb-wb-icon{
    color: var(--adiant-orchid);
}


@media screen and (max-width:992px) {
    .welcome-bx .wb-heading{
        font-size: 36px;
    }
}

@media screen and (max-width:576px) {
    .welcome-bx .wb-heading{
        font-size: 24px;
    }

    .welcome-bx .cb-wb-btn{
        font-size: 20px;
        height: 60px;
    }
    
    .welcome-bx .cb-wb-btn .cb-wb-icon{
        width: 34px;
        height: 34px;
        flex: 0 0 34px;
        padding: 8px;
    }
}
/* --- Dashboard (Welcome bx) end --- */

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */

/* --- Dashboard (cb subscription bx) start --- */
.cb-subscription-bx{
    position: relative;
    padding-right: 150px;
}

.cb-subscription-bx .csb-data{
    position: relative;
    z-index: 1;
}

.cb-subscription-bx .csb-img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 200px;
    z-index: 0;

}

@media screen and (max-width:576px) {
    .cb-subscription-bx{
        padding-right: 0px;
    }
    
    .cb-subscription-bx .csb-img{
        opacity: 0.1;
    }
}
/* --- Dashboard (cb subscription bx) end --- */


/* --- Dashboard (current challenge info) start --- */
.current-challenge-info {
    display: flex;
    gap: 15px;
}

.current-challenge-info .cci-left {
    flex-basis: 320px;
    flex-shrink: 0;
}

.current-challenge-info .cci-right {
    flex-grow: 1;
}

@media screen and (max-width:992px) {
    .current-challenge-info {
        flex-direction: column;
    }

    .current-challenge-info .cci-left {
        flex-basis: inherit;
    }
}
/* --- Dashboard (current challenge info) end --- */


/* --- Dashboard (Account info balance) start --- */
.aib-outer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width:576px) {
    .aib-outer{
        flex-direction: column;
        align-items: inherit;
    }

    .aib-outer .cdi-account .cdi-current{
        width: 100%;
    }
}
/* Dashboard (Account info balance) end */

/* Dashboard (Account info balance) start */
.account-info-balance .aib-name{
    font-size: 14px;
    font-weight: 400;
    opacity: 0.7;
}

.account-info-balance .aib-balance{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 34px;
    font-weight: 700;
    line-height: 34px;
    margin-top: 8px;
}

.account-info-balance .aib-balance svg{
    width: 32px;
    height: 32px;
}
/* Dashboard (Account info balance) end */



/* Dashboard (cb trade performance meter bx) start */
.cb-trade-performance-meter .tpm-inner{
    padding: 15px;
}


.cb-trade-performance-meter .tpm-info{
    padding: 10px 15px;
    background: var(--white-10);
}

.cb-trade-performance-meter .tpm-info .tpm-i-item{
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    color: var(--white-70);
    font-weight: 400;
    margin-bottom: 8px;
}

.cb-trade-performance-meter .tpm-info .tpm-i-item:last-child{
    margin-bottom: 0px;
}

.cb-trade-performance-meter .tpm-info .tpm-i-item .tii-squary{
    width: 12px;
    height: 12px;
    border-radius: 4px;
    background-color: currentColor;
}
/* Dashboard (cb trade performance meter bx) end */


/* Dashboard (conservative trader bx) start */
.cb-conservative-trader .ctb-img{
    width: 100%;
    margin: auto;
    display: block;
    margin-bottom: 10px;
}

.cb-conservative-trader .common-progress-bx{
    padding-top: 0;
}

.cb-conservative-trader .common-progress-bar{
    height: 8px;
}

.cb-conservative-trader .common-progress-data .cpd-value,
.cb-conservative-trader .common-progress-data .cpd-label{
    font-size: 12px;
}

@media screen and (max-width:992px) and (min-width:576px) {
    .cb-conservative-trader{
        display: flex;
    }

    .cb-conservative-trader .ctb-img{
        width: 40%;
        margin: 0;
        object-fit: cover;
    }

    .cb-conservative-trader .ctb-inner{
        flex-grow: 1;
    }
}
/* Dashboard (conservative trader bx) end */



/* --- Dashboard (Location map start) --- */
.location-map-bx{
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 180px;
}

@media screen and (max-width:992px) {
    .location-map-bx{
        height: 240px;
    }
}
/* --- Dashboard (Location map end) --- */

/* --- Dashboard page end --- */



/* --- Account list bx start --- */
.account-list-box{
    padding: 0;
}

.account-list-box .alb-tabs{
    padding: 10px 15px;
    border-bottom: 1px solid var(--white-10);
}

.account-list-box .alb-tabs .common-tabs-bx .ctb-item{
    height: 40px;
}
.account-list-box .alb-data{
    padding: 15px;
}

/* .account-list-box .alb-tabs{
    padding: 15px;
} */
/* --- Account list bx end --- */



/* --- Account detail page start --- */

/* Account detail (account info bx) start */
.account-info-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px 0 0px;
}

.account-info-bx .aib-account-label {
    font-size: 14px;
    font-weight: 400;
    color: var(--white-70);
    margin-bottom: 8px;
}

.account-info-bx .aib-inner-bx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.account-info-bx .aib-inner-bx .aib-i-img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
}

.account-info-bx .aib-inner-bx .aib-i-img img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.account-info-bx .aib-inner-bx .aib-i-data .aib-i-name {
    color: var(--white);
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.account-info-bx .aib-inner-bx .aib-i-data .aib-i-name .account-status-bx{
    position: relative;
    top: -1px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.account-info-bx .aib-inner-bx .aib-i-data .aib-i-created {
    color: var(--white-70);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
}

@media screen and (max-width:720px) {
    .account-info-bx {
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
        gap: 15px;
    }
}
/* Account detail (account info bx) end */



/* Account detail (account basic info) start */
.account-basic-info {
    display: flex;
    flex-direction: column;
}

.account-basic-info .abi-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 0;
    border-top: 1px solid var(--white-05);
}

.account-basic-info .abi-item:first-child {
    padding-top: 0;
    border-top: none;
}

.account-basic-info .abi-item:last-child {
    padding-bottom: 0;
}

.account-basic-info .abi-item .abi-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-70);
    font-size: 14px;
    font-weight: 400;
}

.account-basic-info .abi-item .abi-label svg {
    color: var(--white);
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.account-basic-info .abi-item .abi-data {
    color: var(--white);
    font-size: 14px;
    font-weight: 400;
}

.account-basic-info .abi-item .abi-data.abi-highlight{
    font-size: 20px;
}

.account-basic-info .abi-item .abi-data span {
    display: block;
    font-size: 11px;
    font-weight: 500;
    border-radius: 50px;
    padding: 0px 8px;
    height: 28px;
    letter-spacing: 1px;
    line-height: 26px;
    text-transform: uppercase;
    color: var(--white);
    border: 1px solid var(--adiant-orchid);
    background-color: var(--adiant-orchid);
    background: radial-gradient(45.74% 210.77% at 51.94% 130.65%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
}
/* Account detail (Account basic info) end */


/* Account detail (Limit Card item) start */
.limit-card-bx {
    overflow: hidden;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.limit-card-bx .lc-heading {
    font-size: 16px;
    font-weight: 600;   
    color: var(--white);
    padding: 15px 15px 0;
}

.limit-card-bx .lc-time {
    position: absolute;
    right: 10px;
    top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 6px;
    color: var(--white);
    border: 1px solid var(--adiant-orchid);
    background-color: var(--adiant-orchid);
    background: radial-gradient(45.74% 210.77% at 51.94% 130.65%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
}

.limit-card-bx .lc-time svg {
    width: 15px;
    height: 15px;
}

.limit-card-bx .limit-card-inner {
    position: relative;
    padding: 15px 15px;
}

.limit-card-bx .lc-data-bx {
    color: var(--white-70);
    font-size: 13px;
    font-weight: 400;
    padding: 10px 15px;
    background: var(--white-10);
}

.limit-card-bx .lc-data-bx .ldb-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
}

.limit-card-bx .lc-data-bx .ldb-item:first-child{
    margin-top: 0px;
}

.limit-card-bx .lc-data-bx .ldb-item .li-data{
    color: var(--white);
    font-weight: 500;
}

.limit-card-inner .arc-item .arc-value{
    width: auto;
    font-size: 200%;
    color: var(--white);
    line-height: normal;
    font-family: inherit;
    margin-top: 2px;
}

.limit-card-inner .arc-item .arc-other-value{
    font-size: 80%;
    font-weight: 500;
    font-family: inherit;
    color: var(--white-70);
}
/* Account detail (Limit Card item) end */


/* Account detail (Calender bx) start */
.calender-header{
    position: absolute;
    right: 15px;
    top: 12px;
    gap: 10px;
}

.calender-bx{
    margin: 0px 0 20px;
    overflow: hidden;
}

.calander-days,
.calander-weeks{
    display: flex;
    gap: 5px;
    margin: 5px 0;
}

.calander-days .cd-item,
.calander-weeks .cw-item{
    flex: 1 1 14.285714%;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--white-70);
    text-align: center;
    border: 1px solid var(--smoky-mulberry);
    border-radius: 12px;
}

.calander-weeks .cw-item{
    padding: 10px 0px;
    border: none;
    font-weight: 400;
}

.calander-days .cd-item{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 5px;
    transition: all 0.3s ease-in;
}

.calander-days .cd-item .cd-i-date{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    font-size: 16px;
    font-weight: 600;
}

.calander-days .cd-item .cd-i-event{
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    gap: 6px;
    color: var(--white);
}

.calander-days .cd-item .cd-i-event svg{
    display: none;
    width: 18px;
    height: 18px;
    stroke-width: 2px;
}

.calander-days .cd-item.cd-i-green .cd-i-event svg,
.calander-days .cd-item.cd-i-red .cd-i-event svg{
    display: block;
}

.calander-days .cd-item:not(.cd-i-disabled, .cd-i-seleted):hover{
    background-color: var(--white-05);
}

.calander-days .cd-item.cd-i-red{
    border-color: var(--coral-red-40);
    background: radial-gradient(186.74% 186.74% at 50% 198.06%, var(--coral-red-50) 0%, transparent 100%);
}

.calander-days .cd-item.cd-i-red .cd-i-event svg,
.calander-days .cd-item.cd-i-red .cd-i-date{
    color: var(--coral-red);
}

.calander-days .cd-item.cd-i-green{
    border-color: var(--aquamarine-40);
    background: radial-gradient(186.74% 186.74% at 50% 198.06%, var(--aquamarine-50) 0%, transparent 100%);
}

.calander-days .cd-item.cd-i-green .cd-i-event svg,
.calander-days .cd-item.cd-i-green .cd-i-date{
    color: var(--aquamarine);
}

.calander-days .cd-item.cd-i-disabled .cd-i-event,
.calander-days .cd-item.cd-i-disabled .cd-i-date{
    opacity: 0.3;
}

.calander-days .cd-item.cd-i-seleted{
    background-color: var(--white-05);
}

@media screen and (max-width:768px) {
    .calander-days .cd-item{
        padding: 5px;
    }

    .calander-days .cd-item .cd-i-event{
        display: none;
    }
}
/* Account detail (Calender bx) end */


/* Account detail (Scale overview item) start */
.scale-overview-item .common-progress-data:first-child {
    margin-bottom: 10px;
}
/* Account detail (Scale overview item) end */

.alert-disclaimer-bx .atb-text{
    color: var(--red3-color);
}

/* --- Account detail page end --- */



/* --- New Challenge page start --- */

/* --- New Challenge (Challenge form box .common-box) start --- */
.challenge-form-box{
    display: flex;
    padding: 0;
    max-width: 1024px;
    margin: auto;
}

.challenge-form-box .cfb-left {
    width: 200px;
    flex: 0 0 200px;
    padding: 15px;
    border-right: 1px solid var(--white-10);
}

.challenge-form-box .cfb-right {
    padding: 15px;
    flex-grow: 1;
}

@media screen and (max-width: 768px) {
    .challenge-form-box{
        flex-direction: column;
    }

    .challenge-form-box .cfb-left {
        width: 100%;
        flex: inherit;
        border-right: none;
        border-bottom: 1px solid var(--white-10);
    }

    .challenge-form-box .cfb-right {
        flex-grow: inherit;
    }
}
/* --- New Challenge (Challenge form box .common-box) end --- */


/* --- New Challenge (Challenge steps bx) start --- */
.challenge-steps-bx {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 100px;
}

.challenge-steps-bx .csb-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 0px;
    gap: 8px;
}

.challenge-steps-bx .csb-item:first-child {
    padding-top: 0;
}

.challenge-steps-bx .csb-item:last-child {
    padding-bottom: 0;
}

.challenge-steps-bx .csb-item .csb-i-num {
    position: relative;
    width: 36px;
    height: 36px;
    font-size: 15px;
    color: var(--lavender-stone);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 1px var(--lavender-stone);
    border-radius: 50%;
    padding: 10px;
    overflow: hidden;
}

.challenge-steps-bx .csb-item .csb-i-num::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(66.38% 298.87% at 50% -57.2%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
    opacity: 0;
    z-index: -1;
}

.challenge-steps-bx .csb-item .csb-i-num svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.challenge-steps-bx .csb-item .csb-i-label {
    color: var(--lavender-stone);
    font-size: 16px;
    font-weight: 500;
}

.challenge-steps-bx .csb-item .csb-progress-bar {
    position: absolute;
    left: 17px;
    bottom: -15px;
    height: 30px;
    width: 2px;
    border-radius: 20px;
    background-color: var(--lavender-stone);
    overflow: hidden;
}

.challenge-steps-bx .csb-item .csb-progress-bar .csb-progress-inner {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: var(--adiant-orchid);
    opacity: 0;
}

.challenge-steps-bx .csb-item.complated .csb-i-num,
.challenge-steps-bx .csb-item.active .csb-i-num {
    color: var(--white);
    box-shadow:0 0 0 1px var(--velvet-twilight);
}

.challenge-steps-bx .csb-item.complated .csb-i-label,
.challenge-steps-bx .csb-item.active .csb-i-label {
    color: var(--white);
}

.challenge-steps-bx .csb-item.complated .csb-i-num::after,
.challenge-steps-bx .csb-item.active .csb-i-num::after,
.challenge-steps-bx .csb-item.complated .csb-progress-bar .csb-progress-inner,
.challenge-steps-bx .csb-item.active .csb-progress-bar .csb-progress-inner {
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .challenge-steps-bx {
        flex-direction: row;
        justify-content: center;
    }

    .challenge-steps-bx .csb-item {
        padding: 0px 20px;
    }

    .challenge-steps-bx .csb-item:first-child {
        padding-left: 0;
    }
    
    .challenge-steps-bx .csb-item:last-child {
        padding-right: 0;
    }

    .challenge-steps-bx .csb-item .csb-progress-bar {
        height: 2px;
        width: 30px;
        left: inherit;
        bottom: inherit;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media screen and (max-width:576px) {
    .challenge-steps-bx .csb-item{
        padding: 0 15px;
    }

    .challenge-steps-bx .csb-item .csb-progress-bar {
        right: -7.5px;
        width: 15px;
    }

    .challenge-steps-bx .csb-item .csb-i-num {
        width: 30px;
        height: 30px;
        padding: 8px;
    }

    .challenge-steps-bx .csb-item .csb-i-label {
        font-size: 14px;
    }
}

@media screen and (max-width:380px) {
    .challenge-steps-bx .csb-item .csb-i-num {
        width: 24px;
        height: 24px;
        padding: 6px;
    }

    .challenge-steps-bx .csb-item .csb-i-label {
        font-size: 14px;
    }
}

@media screen and (max-width:340px) {
    .challenge-steps-bx .csb-item{
        flex-direction: column;
        width: 33.33%;
    }
}
/* --- New Challenge (Challenge steps bx) end --- */


/* --- New Challenge (Challenge Detail) start --- */
.challenge-detail {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    margin: auto;
    padding: 0;
}

.challenge-detail .cd-top-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid-50) 0%, var(--obsidian-mist-50) 100%);
    padding: 20px 15px;
}

.challenge-detail .cd-img {
    width: 30px;
    height: 30px;
}

.challenge-detail .cd-trader{
    font-size: 12px;
    border-radius: 50px;
    border: 1px solid var(--white-20);
    color: var(--white);
    background-color: var(--white-10);
    padding: 6px 10px;
    font-weight: 400;
    width: fit-content;
}

.challenge-detail .cd-inner{
    padding: 15px 15px;
}

.challenge-detail .cd-amount {
    color: var(--white);
    font-size: 24px;
    font-weight: 600;
}

.challenge-detail .cd-amount span{
    font-weight: 300;
    font-size: 14px;
}

.challenge-detail .cd-heading {
    color: var(--white-70);
    font-size: 14px;
    font-weight: 400;
}

.cd-rules-info {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.cd-rules-info .cd-r-item {
    color: var(--white);
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 5px;
}

.cd-rules-info .cd-r-item svg {
    width: 20px;
    height: 20px;
    color: var(--white);
    border: 1px solid var(--white);
    position: relative;
    top: 0px;
    border-radius: 50%;
    padding: 4px;
}

.challenge-detail .cd-more-info {
    font-size: 12px;
    font-weight: 300;
    color: var(--white-70);
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.challenge-detail .cd-more-info a {
    color: var(--white);
    font-weight: 400;
    letter-spacing: 1px;
}

.challenge-detail .cd-more-info a:hover {
    color: var(--white);
    text-decoration: underline;
}

.challenge-detail .cd-sgc-ci {
    padding: 0;
    margin-bottom: 0px;
}

.challenge-detail .cd-sgc-ci .checkmark-label {
    font-size: 12px;
}

.challenge-detail .cd-sgc-ci .checkmark-label .checkmark-icon{
    position: relative;
    top: -1px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;

}

.challenge-detail .common-submit-btn{
    font-size: 14px;
}
/* --- New Challenge (Challenge Detail) end --- */


/* --- New Challenge (Challenge trading rules) start --- */
.challenge-trading-rules {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--white-10);
    background-color: var(--white-05);
}

.challenge-trading-rules .ctr-item {
    color: var(--white-70);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    gap: 8px;
}

.challenge-trading-rules .ctr-item a {
    text-decoration: underline;
    color: var(--white-70);
}

.challenge-trading-rules .ctr-item a:hover {
    color: var(--white);
}

.challenge-trading-rules .ctr-item svg {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    position: relative;
    top: 2px;
    color: var(--white);
    border: 1px solid var(--white);
    border-radius: 50%;
    padding: 4px;
}
/* --- New Challenge (Challenge trading rules) end --- */


/* --- New Challenge (Payment method item) end --- */
.payment-method-item {
    position: relative;
    cursor: pointer;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: all 0.3s ease-in-out;
    padding: 6px 6px;
    overflow: hidden;
    border: 1px solid var(--white-15);
}

.payment-method-item .pmi-img {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    object-position: center;
    object-fit: contain;
}

.payment-method-item .pmi-data-bx .pmi-heading {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.payment-method-item .pmi-data-bx .pmi-pera {
    color: var(--white-70);
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}

.payment-method-item::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid) 0%, var(--adiant-orchid-15) 100%);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.payment-method-item:hover {
    border-color: var(--velvet-twilight);
}

.payment-method-item:hover::after {
    opacity: 1;
}
/* --- New Challenge (Payment method item) end --- */


/* --- New Challenge (Payment tab bx) start --- */
.payment-tab-bx {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.payment-tab-item {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
    border: 1px solid var(--white-15);
    padding: 6px 12px 6px 6px;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.payment-tab-item .payment-img {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    padding: 5px;
    object-position: center;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid transparent;
    margin-right: 10px;
    background-color: var(--white);
    transition: all 0.3s ease-in-out;
}

.payment-tab-item:not(.active):hover {
    border-color: var(--white-30);
}

.payment-tab-item::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid) 0%, var(--adiant-orchid-15) 100%);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.payment-tab-item.active::after,
.payment-tab-item.active .payment-img,
.payment-tab-item:hover .payment-img {
    opacity: 1;
}
/* --- New Challenge (Payment tab bx) end --- */


/* --- New Challenge (Payment data bx) start --- */
.payment-data-bx {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.payment-data-bx.pdb-style-3,
.payment-data-bx.pdb-style-2 {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid var(--white-10);
    background-color: var(--white-05);
}

.payment-data-bx .pdb-item .pdb-i-lable {
    color: var(--white-70);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: -3px;
}

.payment-data-bx .pdb-item .pdb-i-data {
    font-size: 14px;
    color: var(--white);
    font-weight: 400;
    margin: 5px 0 0;
}

.payment-data-bx .pdb-item .pdb-i-data.pid-style-2 {
    position: relative;
    padding-right: 34px;
    width: fit-content;
}

.payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: var(--white-70);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    padding: 5px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: absolute;
    right: 0;
    top: -3px;
}

.payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon:hover {
    color: var(--adiant-orchid);
}

.payment-data-bx .pdb-item .pdb-i-data strong {
    color: var(--adiant-orchid);
    font-weight: 400;
}

.upi-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upi-item.pdb-item .pdb-i-lable {
    font-size: 14px;
}

.qr-code-box img {
    max-width: 180px;
    width: 100%;
    display: block;
    margin: auto;
}

.qr-code-box img:nth-child(2) {
    max-width: 320px;
}
/* --- New Challenge (Payment data bx) end --- */


/* --- New Challenge (Payment details) start --- */
.challenge-payment-details .cpd-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--white-10);
}

.challenge-payment-details .cpd-item:last-child {
    border-bottom: none;
}

.challenge-payment-details .cpd-item .cpd-title {
    color: var(--white-70);
    font-size: 14px;
    font-weight: 400;
}

.challenge-payment-details .cpd-item .cpd-amount {
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
}

.challenge-payment-details .cpd-item.cpd-gt-item {
    padding: 0;
}

.challenge-payment-details .cpd-item.cpd-gt-item .cpd-title {
    color: var(--white);
}

.challenge-payment-details .cpd-item.cpd-gt-item .cpd-amount,
.challenge-payment-details .cpd-item.cpd-gt-item .cpd-title {
    font-size: 20px;
}
/* --- New Challenge (Payment details) end --- */

/* --- New Challenge page end --- */


/* --- Payouts page start --- */
.payouts-form-box{
    max-width: 768px;
    width: 100%;
    margin: auto;
}

/*  */
.affiliate-balance{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    border-radius: 50px;
    border: 1px solid var(--white-20);
    background-color: var(--white-05);
    padding: 8px 15px;
    gap: 8px;
}

.affiliate-balance .ab-balance,
.affiliate-balance .ab-name{
    color: var(--white-70);
    font-weight: 400;
    font-size: 18px;
}

.affiliate-balance .ab-balance{
    color: var(--adiant-orchid);
    opacity: 1;
    font-weight: 600;
}
/*  */
/* --- payouts page end --- */


/* --- Leaderboard page start --- */

/* Leaderboard (Best in today) start */
.best-today-row{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.best-today-item{
    position: relative;
    z-index: 1;
    width: 32.00%;
    padding: 12px;
    border-radius: 24px;
    overflow: hidden;
}

.best-today-item .bti-avatar{
    width: 64px;
    height: 64px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: linear-gradient(180deg, var(--white) 0%, transparent 55%, var(--white) 100%);
    clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
}

.best-today-item .bti-avatar .bti-avatar-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
    transform: scale(0.95);
    clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
}

.best-today-item .bti-name{
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
    margin-top: 6px;
}

.best-today-item .bti-type{
    color: var(--white-70);
    font-size: 14px;
    font-weight: 500;
}


.best-today-item .bti-rank-img{
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 140px;
    object-fit: contain;
    object-position: center;
}

.best-today-row .best-today-item:nth-child(1){
    background: linear-gradient(180deg, #E501FA 0%, #8D01AB 100%);
}

.best-today-row .best-today-item:nth-child(2){
    background: linear-gradient(180deg, #00A3F8 0%, #015EAD 100%);
}

.best-today-row .best-today-item:nth-child(3){
    background: linear-gradient(180deg, #FEA401 0%, #F16101 100%);
}

.best-today-item .bti-inner{
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
}

.best-today-item .bti-i-item .bii-lable{
    font-size: 10px;
    font-family: 'Manrope';
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--white-70);
}

.best-today-item .bti-i-item .bii-data{
    color: var(--white);
    font-size: 18px;
    font-weight: 600;
}

.best-today-item .bti-i-item .bii-data.bd-small{
    font-size: 14px;
    font-weight: 500;
}

@media screen and (max-width:992px) {
    .best-today-item .bti-rank-img{
        width: 101px;
    }

    .best-today-item .bti-name{
        font-size: 16px;
    }
}

@media screen and (max-width:700px) {
    .best-today-item .bti-inner{
        flex-direction: column;
    }
    .best-today-item .bti-rank-img{
        width: 71px;
    }
}

@media screen and (max-width:500px) {
    .best-today-row{
        flex-direction: column;
        gap: 15px;
    }

    .best-today-item{
        width: 100%;
    }

    .best-today-item .bti-inner{
        flex-direction: row;
    }

    .best-today-item .bti-rank-img{
        width: 140px;
    }
}

@media screen and (max-width:360px) {
    .best-today-item .bti-rank-img{
        width: 101px;
    }
}

/* Leaderboard (Best in today) end */

/* Leaderboard (Best in type) start */
.best-type-bx,
.best-type-bx .btb-user-bx,
.best-type-bx .btb-data,
.best-type-bx .btb-inner{
    display: flex;
    align-items: center;
}

.best-type-bx{
    position: relative;
    padding: 10px 24px;
    border-radius: 50px;
    gap: 15px;
    background: radial-gradient(45.74% 210.77% at 51.94% 130.65%, var(--adiant-orchid-20) 0%, var(--obsidian-mist-20) 100%);
    overflow: hidden;
}

.best-type-bx::before,
.best-type-bx::after{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: radial-gradient(46.05% 211.96% at 50% 59.68%, var(--adiant-orchid) 0%, transparent 100%);
}

.best-type-bx::before{
    top: 0;
}

.best-type-bx::after{
    bottom: 0;
}

.best-type-bx .btb-heading{
    font-size: 12px;
    font-weight: 400;
    color: var(--white);
    letter-spacing: 1px;
}

.best-type-bx .btb-inner{
    gap: 15px;
    justify-content: space-between;
    flex-grow: 1;
}

.best-type-bx .btb-user-bx{
    gap: 10px;
}

.best-type-bx .btb-user-bx .btb-img-bx{
    content: "";
    width: 50px;
    height: 50px;
    background: linear-gradient(180deg, var(--neon-fuchsia) 0%, transparent 55%, var(--neon-fuchsia) 100%);
    clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
}

.best-type-bx .btb-user-bx .btb-user-img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    transform: scale(0.95);
    clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
}

.best-type-bx .btb-user-bx .btb-name{
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
}

.best-type-bx .btb-user-bx .btb-amount{
    color: var(--white-70);
    font-size: 12px;
    margin-top: 2px;
    display: none;
}

.best-type-bx .btb-data{
    border: 1px solid var(--adiant-orchid-50);
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    gap: 4px;
    padding: 2px 10px;
    border-radius: 50px;
    line-height: 0;
    height: 28px;
}

.best-type-bx .btb-data svg{
    width: 12px;
    height: 12px;
    transform: rotate(-90deg);
    stroke-width: 3px;
}

@media screen and (max-width:990px) {
    .best-type-bx{
        flex-direction: column;
        align-items: inherit;
        gap: 8px;
    }
}

@media screen and (max-width:768px) {
    .best-type-bx .btb-user-bx .btb-name{
        font-size: 14px;
    }
}
/* Leaderboard (Best in type) end */


/* Leaderboard (Best Accounts In Profi) start */
.leaderboard-filter{
    position: absolute;
    right: 0;
    top: -7px;
}

.leaderboard-filter .cdi-current{
    line-height: 0;
    color: var(--deep-eclipse);
    background-color: var(--white);
}

.tr-leaderboard{
    border-radius: 0px;
    border: none;
}

.ct-leaderboard{
    border-collapse: separate;
    border-spacing: 0 4px;
}

.ct-leaderboard thead{
    background-color: transparent;
}

.custom-table.ct-leaderboard thead th{
    color: var(--white-70);
    font-weight: 600;
    padding: 2px 15px;
}

.ct-leaderboard tbody tr{
    position: relative;
}

.ct-leaderboard tbody tr .ct-l-bg{
    position: absolute;
    left: 0; 
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    border: 1px solid var(--adiant-orchid-30);
    background: radial-gradient(53.91% 248.2% at 50.92% 93.22%, var(--adiant-orchid-15) 0%, var(--obsidian-mist-15) 100%);
    z-index: -1;
}

.ct-leaderboard tbody tr td{
    padding: 6px 15px;
    color: var(--white);
    transition: all 0.3s ease-in-out;
}

.ct-leaderboard tbody tr td .td-s-profit{
    border: 1px solid var(--adiant-orchid-50);
    width: fit-content;
    height: 28px;
}

.ct-leaderboard tbody tr td .td-s-profit svg{
    width: 14px;
    height: 14px;
    transform: rotate(-90deg);
    stroke-width: 3px;
}

.ct-leaderboard tbody tr:hover td{
    cursor: pointer;
    background-color: var(--white-05);
}

.ct-leaderboard tbody tr td:first-of-type{
    border-radius: 50px 0 0 50px;
}

.ct-leaderboard tbody tr td:last-of-type{
    border-radius: 0 50px 50px 0;
}

.ct-leaderboard tbody tr td .rank-img{
    width: 28px;
    height: 28px;
    object-fit: contain;
    object-position: center;
    margin: auto;
    display: block;
}

.ct-leaderboard tbody tr td .badge-img{
    width: 50px;
    height: 50px;
    object-fit: contain;
    object-position: center;
    margin: auto;
    display: block;
}

@media screen and (max-width:360px) {
    .leaderboard-filter{
        position: static;
        right: inherit;
        top: inherit;
        width: 100%;
    }
}
/* Leaderboard (Best Accounts In Profi) end */

/* --- Leaderboard page end --- */



/* --- Calendar page start --- */

/* Calendar (filter) start */
.prediction-filter-bx .pfb-drop-bx .pfb-drop-item,
.prediction-filter-bx .pfb-drop-bx,
.prediction-filter-bx{
    display: flex;
    align-items: center;
    gap: 20px;
}

.prediction-filter-bx{
    padding: 5px 0px 20px;
    max-width: 1200px;
    margin: auto;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.prediction-filter-bx .calendar-navigation-bx .cnb-date{
    min-width: 130px;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item,
.prediction-filter-bx .pfb-drop-bx{
    width: 100%;
    max-width: 240px;
    flex-shrink: 0;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item{
    gap: 10px;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item .sgr-outer-label{
    font-size: 12px;
    margin: 0;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item .common-drop-item {
    margin-bottom: 0;
    width: 100%;
}

.prediction-filter-bx .pfb-drop-bx .pfb-drop-item .cdi-current {
    line-height: 0;
    text-transform: capitalize;
}
/* Calendar (filter) end */

/* Calendar (prediction table) start */

.cb-prediction-box{
    box-shadow: 0 0 0 1px var(--smoky-mulberry);;
}


.ct-prediction thead tr th:not(:last-child),
.ct-prediction tbody tr td:not(:last-child){
    border-right: 1px solid var(--smoky-mulberry);
}

.ct-prediction thead tr th,
.ct-prediction tbody tr:not(:last-child) td{
    border-bottom: 1px solid var(--smoky-mulberry);
}

.ct-prediction thead tr th.th-ctp-time,
.ct-prediction tbody tr td.td-ctp-time{
    position: sticky;
    left: 0;
    z-index: 1;
    min-height: 100px;
    padding: 0px;
}

.ct-prediction thead tr th.th-ctp-time::after,
.ct-prediction tbody tr td.td-ctp-time::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% + 1px);
    height: 100%;
    background-color: #07080b;
    border-right: 1px solid var(--smoky-mulberry);
}


/*  */
.ct-prediction thead{
    background-color: transparent;
}

.ct-prediction thead tr th.th-time{
    width: 60px;
}

.ct-prediction thead tr th.th-ctp-time{
    min-height: inherit;
}

.ct-prediction thead tr th.th-ctp-time::after{
    border-radius: 16px 0 0 0;
}

.ct-prediction thead tr th .ctp-date-item{
    text-align: center;
    font-size: 12px;
}

.ct-prediction thead tr th .ctp-date-item .ctp-date{
    position: relative;
    width: 34px;
    height: 34px;
    border-radius: 50px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: auto;
    margin-bottom: 10px;
    border: 1px solid transparent;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.ct-prediction thead tr th .ctp-date-item .ctp-date::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    background: radial-gradient(66.38% 298.87% at 50% -57.2%, var(--adiant-orchid) 0%, var(--obsidian-mist) 100%);
    transition: all 0.3s ease-in-out;
}

.ct-prediction thead tr th .ctp-date-item .ctp-date.ctp-active::after,
.ct-prediction thead tr th .ctp-date-item .ctp-date.ctp-active{
    border-color: var(--white-10);
    opacity: 1;
}

/*  */
.ct-prediction tbody tr td{
    vertical-align: top;
}

.ct-prediction tbody tr:last-child td.td-ctp-time::after{
    border-radius: 0 0 0 16px;
}

.ct-prediction tbody tr td:not(.td-ctp-time){
    padding: 12px;
}

.ct-prediction tbody tr td.td-ctp-time .ctp-time{
    position: relative;
    z-index: 1;
    width: 60px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1px;
    color: var(--white);
    font-weight: 500;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
}

.ct-prediction .ctp-item-bx{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.prediction-card-item{
    user-select: none;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 170px;
    border-radius: 12px;
    padding: 12px;
    background: radial-gradient(186.74% 186.74% at 50% 198.06%, var(--adiant-orchid-30) 0%, transparent 100%);
    border: 1px solid var(--adiant-orchid-60);
    transition: all 0.3s ease-in-out;
}

.prediction-card-item:not(.pci-full):hover{
    background-color: var(--white-05);
}

.prediction-card-item.pci-full{
    width: 100%;
    cursor: inherit;
    background-color: var(--white-05);
}

.prediction-card-item .pci-impact{
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 50px;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: 600;
}

.prediction-card-item .pci-event,
.prediction-card-item .pci-currency{
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
    letter-spacing: 1px;
}

.prediction-card-item .pci-currency{
    text-align: right;
}

.prediction-card-item .pci-event{
    color: var(--white);
    margin-top: 8px;
    /* word-break: break-word; */
    white-space: normal;
    line-height: 1.6;
    font-size: 12px;
    font-weight: 400;
}

.prediction-card-item .pci-separator{
    width: 100%;
    height: 1px;
    background-color: var(--white-10);
}

.prediction-card-item .pci-data{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: var(--white-70);
}

.prediction-card-item .pci-data span{
    color: var(--white);
}

.prediction-card-item .pci-text{
    white-space: normal;
    font-size: 12px;
    line-height: 1.6;
    text-align: justify;
}
/* Calendar (prediction table) end */

@media screen and (max-width:840px) {
    .prediction-filter-bx {
        flex-direction: column;
        align-items: flex-start;
    }

    .prediction-filter-bx .pfb-drop-bx .pfb-drop-item, 
    .prediction-filter-bx .pfb-drop-bx {
        width: 100%;
        max-width: inherit;
        flex-shrink: inherit;
    }
}

@media screen and (max-width:576px) {
    .prediction-filter-bx .pfb-drop-bx{
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .prediction-filter-bx .pfb-drop-bx .pfb-drop-item .sgr-outer-label{
        width: 70px;
    }

    .prediction-filter-bx .calendar-navigation-bx {
        justify-content: space-between;
        width: 100%;
    }


    .ct-prediction thead tr th.th-ctp-time,
    .ct-prediction tbody tr td.td-ctp-time,
    .ct-prediction tbody tr td.td-ctp-time .ctp-time{
        width: 50px;
    }

    .prediction-card-item .pci-event,
    .ct-prediction .ctp-time{
        font-size: 12px;
    }
}
/* --- Calendar page end --- */


/* --- Affiliate data item start --- */

/* Affiliate (Referral Code) start */
.referral-btn .atb-btn{
    flex-direction: row-reverse;
    padding: 4px 4px 4px 15px;
    height: auto;
    white-space: wrap;
    word-break: break-all;
    justify-content: inherit;
    color: var(--white);
    border-color: var(--white-20);
    background-color: var(--white-20);
}

.referral-btn .atb-btn svg{
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    padding: 4px;
    border-radius: 50%;
    color: var(--white);
    background-color: var(--dark-purple);
}

.referral-btn .atb-btn svg path{
    transform: scale(0.7);
    transform-origin: center;
}

.referral-btn .atb-btn:hover{
    color: var(--white);
    border-color: var(--velvet-twilight);
    background-color: var(--velvet-twilight);
}
/* Affiliate (Referral Code) end */


/* Affiliate (affiliate program bx) start */
.affiliate-program-bx{
    padding: 10px 15px;
}

.affiliate-program-bx .atb-heading{
    font-weight: 400;
    line-height: 1.6;
}

.affiliate-program-bx .atb-heading svg{
    top: -1px;
}
/* Affiliate (affiliate program bx) end */


/* Affiliate (Data item) start */
.affiliate-data-item{
    border-radius: 12px;
    padding: 20px;
    padding-left: 70px;
}

.affiliate-data-item .adi-icon{
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: var(--white);
}

.affiliate-data-item .adi-icon svg{
    width: 36px;
    height: 36px;
    object-position: center;
    object-fit: contain;
}

.affiliate-data-item .adi-heading{
    color: var(--white-70);
    font-size: 14px;
    font-weight: 500;
}

.affiliate-data-item .adi-data{
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin-top: 6px;
    margin-bottom: -6px;
}
/* Affiliate (Data item) end */

/* --- Affiliate page end --- */



/* --- Settings page start --- */
@media screen and (min-width:769px) {
    .settings-bx{
        padding-left: 215px;
        max-width: 806px;
        margin: auto;
    }
    
    .settings-bx .ctb-settings{
        position: absolute;
        left: 0;
        top: 0;
        width: 200px;
        height: 100%;
        padding: 15px;
        border-right: 1px solid var(--white-10);
    }

    .settings-bx .ctb-settings .common-tabs-bx{
        flex-direction: column;
    }
}

@media screen and (max-width:768px) and (min-width:0) {
    .settings-bx{
        padding: 0;
    }

    .settings-bx .ctb-settings{
        padding: 10px 10px;
        border-bottom: 1px solid var(--white-10);
    }

    .settings-bx .profile-bx{
        padding: 15px 3px;
    }
}
/* --- Settings page end --- */